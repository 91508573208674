import { faCog, faEllipsis, faEllipsisV, faEnvelope, faIdBadge, faMessage, faPhone, faPhoneAlt, faTrash, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useCurrentUser } from 'base/app';
import { getByIds } from 'base/get_by_ids';
import { ChatSession, INBOX_MESSAGE_TYPE_SESSION_UPDATE, INBOX_MESSAGE_TYPE_SESSION_USER_UPDATE, INBOX_MESSAGE_TYPE_WA_INCOMING_MSG } from 'base/ui/chat';
import { EditableTable } from 'base/ui/editable_table';
import { GenericException } from 'base/ui/errors';
import { SimpleTableInput, TagsInput, UserBadge } from 'base/ui/misc';
import { EmptyView, LoadingOverlay, LoadingView } from 'base/ui/status';
import { SuggestedField } from 'base/ui/suggested_field';
import { useOnScroll } from 'base/ui/utils';
import { isObject, last } from 'base/utils';
import { defined, getRandomColor, obj2HTML, objToEl, sanitizeToId, useRerender, useRerenderWithValue } from 'base/utils/common';
import { broadcaster, useBroadcastedState, useLocalStorageState } from 'base/utils/events';
import React, { useEffect, useRef, useState } from 'react';
import './css/calendar.css';
import './setupTailwind';
import { NumberBadge } from './components/ui/commonUI';
import { Popup } from 'base/ui/popups';
import { AddTagsWithValues, SearchTagsWithEsValues, ShowTagsWithValues, TagsWithTypesEditor } from './common';
import { EditTicketData, fetchUsersByIdsOnTickets, TicketsList } from './tickets';
import Clipboard from 'base/utils/clipboard';
import { MILLIS_IN_MINUTE } from 'base/constants';

/*
  there is a check box - is staff to add roles,
*/
function EditEmployee({admin_org_user, org_user, updates, org}) {
	const [is_staff, setIsStaff] = useState(Object.keys(org_user.roles || {}).length > 0);
	const current_user = useCurrentUser();

	if (!admin_org_user?.roles.admin && !current_user.roles.org_manager) {
		return <div className='tw-text-red-500'>No Permission</div>;
	}
	return (
		<fieldset className={`tw-flex tw-flex-col tw-gap-2 ${is_staff ? "" : "tw-border-0"}`}>
			<legend className={`tw-flex tw-gap-2 tw-text-sm tw-ml-2`}>
				<input className="w3-check" type='checkbox' checked={is_staff}
					disabled={Object.keys(org_user.roles || {}).length > 0}
					onChange={(e) => {
						setIsStaff(e.target.checked);
					}}
				/>
				<div>Is Staff</div>
			</legend>
			{
				is_staff
					? 	<div className='tw-space-y-2 tw-p-2'>
							<div>Roles</div>
							<div className='tw-text-grey-400 tw-font-sm'>Standard roles: admin, manager, agent</div>
							<SimpleTableInput
								rows={
									Object.entries(org_user.roles || { "": ["*"] })
										.map(([role, permissions]) => [role, permissions.join(", ")])
								}
								nCols={2}
								placeholders={['Role', 'Permissions']}
								onAction={(action, _row, _rows) => { updates.roles = _rows }}
							/>
							<SuggestedField
								props={{
									"show_results_on_render": true,
									"endpoint": `/api/admin/org/${org._id}/users?action=search`,
									"params": { "is_staff": true },
									"results_key_path": "org_users",
									"title_format": "{name}",
									"max_selections": 1,
									"placeholder": "Employee Manager",
									"search_result_className": '!tw-font-normal tw-mt-2 !tw-shadow-none tw-divide-y tw-pl-2',
								}}
								selected={org_user.manager_user && [org_user.manager_user]}
								onSelect={(manager_users) => { updates.manager_user_id = manager_users[0]?.user_id || "" }}
							/>
						</div>
					: null
			}
		</fieldset>
	)
}

function AddUserNameAndPhoneNumber({ org_user, updates }) {
	return (
		<div className='tw-flex tw-flex-col tw-gap-2'>
			<input type='text' className='w3-input' defaultValue={org_user.name || ""}
				onChange={(e) => { updates.name = e.target.value }}
				placeholder='Name'
			/>
			<input type='text' className='w3-input' defaultValue={org_user.phone_number || ""}
				onChange={(e) => { updates.phone_number = e.target.value }}
				pattern='[0-9]{8, 13}' placeholder='Phone Number'
			/>
		</div>
	)
}


/*
	OrgUsers
	filters -> search_text, is_staff, subscribers
*/
function OrgUsersPage({org_id:_org_id}){
	const [admin_org_user, setAdminOrgUser] = useState(null);
	const [org_users, setOrgUsers] = useState([]);
	const [users_count, setOrgUsersCount] = useState(null);
	const [org_id] = useLocalStorageState('org_id', _org_id) || ["test"];
	const [tag_types, setTagTypes] = useBroadcastedState("tag_types", {});
	const ctx = useRef({"filters": {}}).current;
	const rerender = useRerender();
	const [filters_updated, setFiltersUpdated] = useRerenderWithValue();
	const [org, setOrg] = useBroadcastedState("org");
	const usersRef = useRef(null);
	const [show_wallet_column, setShowWalletColumn] = useState(false);

	const fetchAdditionalData = async (org_user) => {
		let manager_user_id = org_user?.manager_user_id;
		if(manager_user_id){
			let cache = await getByIds({"user_ids": [manager_user_id]});
			org_user.manager_user = cache.users[manager_user_id];
		}
	}

	const onOrgUsersSearchReponse = (resp) => {
		let user_ids_to_fetch = [];
		resp.data.org_users.forEach(org_user => {
			org_user.manager_user_id
				&& user_ids_to_fetch.push(org_user.manager_user_id);
			org_user.org_id = org_id;			
		});
		ctx.has_more_users = resp.data.has_more_users;
		ctx.next_users_cursor = resp.data.next_users_cursor;
		setOrgUsersCount(resp.data.users_count);
		getByIds({"user_ids": user_ids_to_fetch}).then((data) => {
			ctx.org_users = ctx.org_users || [];
			for(let org_user of resp.data.org_users) {
				org_user.manager_user = data.users[org_user.manager_user_id];
				ctx.org_users.push(org_user);
			}
			setShowWalletColumn(ctx.org_users.find((org_user) => org_user.wallet));
			setOrgUsers([...ctx.org_users]);
		});
	}

	/* init */
	useEffect(
		() => {
			if (ctx.is_loading) return;
			ctx.is_loading = true;
			rerender();
			axios.post(`/api/admin/org/${org_id}/users`).then(
				(resp) => {
					if(resp.data.errors) {GenericException.showPopup(resp.data.errors); return;}
					resp.data.org && setOrg(resp.data.org);
					resp.data.admin_org_user && setAdminOrgUser(resp.data.admin_org_user);
					resp.data.tag_types && setTagTypes(resp.data.tag_types);
					onOrgUsersSearchReponse(resp);
				}
			).finally(
				() => {
					ctx.is_loading = false;
					rerender();
				}
			);
		}, []
	);

	/* fetch more users */
	const searchAndFetchMoreUsers = () => {
		if (ctx.has_more_users === false || ctx.is_loading) return;
		ctx.is_loading = true;
		rerender();    
		const params = {
			"search_text": ctx.filters.search_text,
			"cursor": ctx.next_users_cursor
		}
		axios.post(`/api/admin/org/${org_id}/users?action=search`, params).then(
			(resp) => {
				if(resp.data.errors) {GenericException.showPopup(resp.data.errors); return;}
				onOrgUsersSearchReponse(resp);
			}
		).finally(() => {ctx.is_loading = false; rerender();});
	}

	/* refetch when filters changed */
	useEffect(
		() => {
			ctx.has_more_users = true;
			ctx.org_users = [];
			searchAndFetchMoreUsers();
		}, [filters_updated]
	); 

	/* auto load more on scroll */
	useOnScroll(
		usersRef.current, 
		(percent) => {percent === 100 && searchAndFetchMoreUsers()},
		[org_users]
	);

	/* handlers */
	const doCreateUser = () => {
		const new_row = {"__is_new": true, "__is_editing": true};
		setOrgUsers([new_row, ...org_users]);
	}

	const openChat = (org_user) => {
		ChatSession.open(
			`wcs_${org_user.user_id}_${org.wa_business_number}`,
			{
				"bottomStatusIndicator": (session_data) => {
					return <OrgUserChatSupportButtons 
						org_id={org_id}
						user_id={org_user.user_id} 
						session_data={session_data} 
						onChatResolved={
							(cs_unseen_count) => {
								if(!cs_unseen_count){
									org_user.wa_user && (org_user.wa_user.cs_unseen_count = 0);
									org_user.rerender?.(); // from editable table
								}
							}
						}
					/>
				},
				...(OrgUsersPage.CHAT_OPTIONS || {})
			}
		);
	}

	const showTagTypesEditorPopup = () => {
		var updated_tag_types = null;
		Popup.show(
			"Update Tag Types",
			<div className='tw-m-2 tw-mb-16'>
				<TagsWithTypesEditor tag_types={tag_types} 
					onUpdated={(tag_types) => updated_tag_types= tag_types}
				/>
			</div>,
			{
				"ok_button": "Update",
				"cb": (action) => {
					action && axios.post(
						`/api/admin/org/${org_id}/users?action=update_tag_types`,
						{"tag_types": updated_tag_types}
					).then((resp) => {resp.data.tag_types && setTagTypes(resp.data.tag_types)});
				}
			}
		)
	}

	const onUsersSettingsClick = (evt) => {
		Popup.showContextMenu(
			evt.currentTarget,
			<div className="tw-p-2">
				<div onClick={showTagTypesEditorPopup}>Edit Tags</div>
			</div>
		);
	}

	const moreOptionsClick = (org_user, evt) => {
		const addRemoveMoney = () => {
			let amount = parseFloat(window.prompt("Enter amount to add/remove from wallet", "0"));
			if(!amount) return;
			/* if invalid amount, show GenericException.showPopup({"Invalid Amount": amount}) */
			if(isNaN(amount)) return GenericException.showPopup({"Invalid Amount": amount});
			axios.post(
				"/api/admin/org/"+org_id+"/users?action=deopsit_wallet", 
				{"amount": amount, "user_id": org_user.user_id}
			).then((resp) => {
				if(resp.data.errors) {GenericException.showPopup(resp.data.errors); return;}
				org_user.wallet = resp.data.deposit_wallet;
				org_user.rerender?.();
			});
		}

		const copyUserId = () => {
			Clipboard.copy(org_user._id);
			Popup.toast("Copied");
		}

		Popup.showContextMenu(
			evt.currentTarget,
			<div className="tw-divide-y">
				<div className='tw-p-1' onClick={addRemoveMoney}>Add/Refund</div>
				<div className='tw-p-1' onClick={copyUserId}>Copy User Id</div>
			</div>
		);
	}

	const removeOrgUser = (org_user) => {
		if(!org_user.user_id) return null;
		if(window.confirm("Are you sure you want to remove this user?") === false) return;
		axios.post(
			`/api/admin/org/${org_id}/users?action=delete_user`,
			{"user_id": org_user.user_id}
		).then((resp) => {
			if(resp.data.errors) {GenericException.showPopup(resp.data.errors); return;}
			org_user.__is_deleted = resp.data.deleted;
			org_user.rerender?.();
		});
	}
	
	if(!ctx.org_users) return <LoadingOverlay />

	return (
		<div className='tw-p-4'>
			<div className='hflex tw-flex-wrap tw-gap-x-4 tw-gap-y-2'>
				<div>
				<div className='tw-text-lg tw-font-semibold'>Users</div>
				<div className='tw-text-xs tw-text-gray-500 tw-mt-2'></div>
				</div>
				<div className='tw-ml-auto tw-flex tw-gap-4 tw-items-center'>
					<button onClick={onUsersSettingsClick} className="tw-mr-4">
						<FontAwesomeIcon icon={faCog} />                
					</button>
					<button className='btn-primary tw-text-xs tw-shrink-0' onClick={() => doCreateUser()}>
					+ Create User
					</button>
				</div>
			</div>
			<div className='tw-flex tw-flex-row tw-flex-wrap tw-gap-4 tw-mt-6 tw-items-center'>
				<div className='tw-flex-grow'>
					<SearchTagsWithEsValues
						tag_types={tag_types}
						onTextSearch={(search_text) => {ctx.filters.search_text= search_text; setFiltersUpdated()}}
						onTagsChange={(tags) => {ctx.filters.tag_types = tags; setFiltersUpdated();}}
					/>
				</div>
				{
					users_count?.value
					?  	<div className='tw-inline-block tw-ml-auto tw-text-sm tw-rounded-lg tw-px-2 tw-py-1'>
							<span className='tw-font-bold '>
								{users_count.relation.startsWith("gt") ? ">" : null} {users_count.value}
							</span> Users
						</div>
					:	null
				}
			</div>
			{/* TODO: Place filter selection values here */}
			{/* Table View */}
			<div className={"tw-mt-4 tw-overflow-y-auto tw-w-full"} ref={usersRef}>
				<EditableTable
					cols={[
						{
							"header": 'Name',
							"render": (org_user) => {
								if(!org_user.user_id) return null;
								return (
									<div className='tw-text-sm'>
										<UserBadge user={org_user} />
									</div>
								)
							},
							"editor": (org_user, updates) => {
								if(org_user.user_id) return undefined; // render default
								return <AddUserNameAndPhoneNumber org_user={org_user} updates={updates} />
							}
						},
						{
							"title": "Tags/Data",
							"render": (org_user) => {
								let open_tickets = org_user.open_tickets || {}
								if(!org_user.tags && !Object.keys(open_tickets).length) return null;
								let open_unpaid_tickets = Object.entries(open_tickets).filter(
									([ticket_id, quick_ticket_data]) => quick_ticket_data.amount_due
								);
								return (
									<div className='tw-text-sm'>
										<div className='tw-flex tw-gap-2 tw-flex-wrap tw-text-xs tw-font-medium'>
											{
												open_unpaid_tickets.length > 0
												?	<div className='tw-bg-red-200 tw-px-2 tw-py-1 tw-rounded-full tw-text-red-500'>
														{open_unpaid_tickets.length} Unpaid Tickets
													</div>
												:	null
											}
											{
												Object.keys(open_tickets).length - open_unpaid_tickets.length > 0
												?	<div className='tw-bg-blue-200 tw-px-2 tw-py-1 tw-rounded-full tw-text-blue-500'>
														{Object.keys(open_tickets).length - open_unpaid_tickets.length} Open Tickets
													</div>
												:	null
											}
											{	
												org_user.tags && Object.entries(org_user.tags).map(
													([tag, value]) => (
														<div className='tw-bg-gray-bg tw-px-2 tw-py-1 tw-rounded-full'
															key={tag}
															title={value}
														>
															{tag}
														</div>
													)
												)
											}
										</div>
										{
											org_user.system_tags && Object.keys(org_user.system_tags).length > 0
											? 	<div className='tw-border-t'>
													<div className='tw-flex tw-gap-2 tw-flex-wrap tw-text-xs tw-font-medium'>
														{
															Object.entries(org_user.system_tags)?.map(
																([tag, value]) => (
																	<div className='tw-bg-gray-bg tw-px-2 tw-py-1 tw-rounded-full' key={tag}
																		title={value}
																	>
																		{tag}
																	</div>
																)
															)
														}
													</div>
												</div>
											: null
										}
										{
											org_user.data && Object.keys(org_user.data).length > 0
											? 	<div className='tw-mt-2'>
													{objToEl(org_user.data)}
												</div>
											: null
										}
									</div>
								)
							},
							"editor": (org_user, updates) => {
								return (
									<>
										<div className='tw-mb-4'>
											<AddTagsWithValues
												tag_types={tag_types}
												selected={org_user.tags}
												onTagsChange={(tags) => {updates.tags = tags}}
											/>
										</div>
										<div>
											<div className='tw-text-sm tw-font-semibold'>Add/Remove Data</div>
											<SimpleTableInput nCols={2} rows={Object.entries(org_user.data || {})}
												onAction={(action, _row, _rows) => {updates.data = _rows}} 
											/>
										</div>
									</>
								)
							},
							"is_col_editable": true
						},
						{
							/* Employee */
							"title": "Roles/Permissions",
							"render": (org_user) => {
								return (
									<div>
										{
											org_user.roles
											? 	<div className='tw-flex tw-flex-row tw-flex-wrap tw-text-sm'>
													{Object.entries(org_user.roles).map(
														([role, permissions]) => {
															return <div key={role} className='tw-p-1 tw-border tw-rounded-lg tw-text-white tw-inline'
																style={{"backgroundColor": getRandomColor(role)}}
															>{role}</div>
														}
													)}
												</div>
											: null
										}
										{
											org_user.manager_user
											?  <>Manager: <div className='tw-rounded-3xl tw-bg-gray-500 tw-px-2 tw-py-1 tw-inline tw-text-white'>
												{org_user.manager_user.name}
												</div></>
											: null
										}
									</div>
								)
							},
							"editor": (org_user, updates) => {
								return <EditEmployee org_user={org_user} updates={updates} admin_org_user={admin_org_user} org={org}/>
							},
							"is_col_editable": true
						},
						show_wallet_column && {
							/* Wallet */
							"header": "Wallet",
							"render": (org_user) => {
								let wallet = org_user.wallet;
								if(!wallet) return null;
								return (
									<div className="tw-pr-2 tw-gap-1">
										{wallet.wallet_amount <= 0 ? "Credit Used:": "Wallet:"}&nbsp;
										<span className={`${wallet.wallet_amount === 0 ? 'tw-text-black' : wallet.wallet_amount < 0 ? 'tw-text-red-600' : 'tw-text-green-600'} tw-text-sm`}>{wallet.wallet_amount_str}</span>
										{
											wallet.minimum_wallet_amount && wallet.minimum_wallet_amount < 0
												&& <span className='tw-text-xs tw-px-2'>
													Credit: <span className="tw-text-xs">{wallet.credit_amount_str}</span>
												</span>
										}
									</div>
								)
							}
						},
						{
							/* CUSTOMER SUPPORT */
							"render": (org_user) => (
								<div className='tw-relative tw-w-fit tw-p-2 tw-cursor-pointer'
									onClick={(e) => {e.stopPropagation(); openChat(org_user)}} 
								>
									{
										org_user.wa_user?.cs_unseen_count > 0
										? <NumberBadge val={org_user.wa_user?.cs_unseen_count} />
										: null
									}
									<FontAwesomeIcon icon={faMessage} className='tw-text-sm tw-text-gray-700 tw-absolute tw-left-0' />
								</div>
							)
						},
					]}
					actions={[
						[<span key="remove">Remove</span>, removeOrgUser],
						[<div className='tw-px-2' key="more"><FontAwesomeIcon icon={faEllipsisV} /></div>, moreOptionsClick]
					]}
					rows={org_users} 
					callbacks={{
						"onRowClick": (org_user) => {
							Popup.sideSheet(
								<UserFullDetails org_id={org._id} user_id={org_user.user_id} 
									org_user={org_user} 
								/>
							);
						},
						"onUpdate": async (org_user, updates) => {
							let resp = null;
							let overrides = {};
							if(updates.tags){
								updates.tags = Object.fromEntries(
									Object.entries(updates.tags).map(([tag, value]) => [tag, parseInt(value) || 0])
								);
							}
							if(updates.data){
								overrides.data = Object.fromEntries(
									updates.data.map(([key, value]) => [sanitizeToId(key), value.trim() || null])
								);
							}

							if(!org_user.user_id){
								resp = await axios.post(
									`/api/admin/org/${org_id}/users?action=create`,
									{...updates, ...overrides}
								);
							}
							else {
								resp = await axios.post(
									`/api/admin/org/${org_id}/user/${org_user.user_id}?action=update`,
									{...updates, ...overrides}
								);
							}
							await fetchAdditionalData(resp.data.org_user);
							return [resp.data.org_user, resp.data.errors]; 
						}
					}}
				/>
			</div>
		</div>
	)
}

/* 
	Chat support buttons
	- Resolve chat
	- Toggle bot active
	- Force take over customer support
*/
function OrgUserChatSupportButtons({
	org_id, user_id, session_data, onChatResolved, wrapper=true
}){
	const wa_user = session_data.wa_user || {};
	const org_user = session_data.org_user || {};
	const [is_page_visible] = useBroadcastedState("visibility_changed", true);
	const [cs_unseen_count, setCsUnseenCount] = useState(wa_user.cs_unseen_count);

	const [cs_staff_id, setCsStaffId] = useState(wa_user.cs_staff_id);
	const [cs_staff_user, setCsStaffUser] = useState(null);
	const [cs_active_until, setCsActiveUntil] = useState(wa_user.cs_active_until || 0);

	const ctx = useRef({}).current;
	const user = useCurrentUser();


	const ping_cs_active = (force) => {
		if(wa_user.last_cs_ping_at && wa_user.last_cs_ping_at > now_millis - 30 * 1000) return;
		wa_user.last_cs_ping_at = now_millis;
		const last_user_im_created_at = last(
			session_data.inbox_messages,
			(im) => {return im.src_id === user_id && im._type === INBOX_MESSAGE_TYPE_WA_INCOMING_MSG}
		)?.created_at;

		axios.post(
			`/api/admin/org/${org_id}/user/${user_id}`,
			{
				"action": "cs_active",
				"force": force,
				"last_user_im_created_at": last_user_im_created_at
			}
		).then((resp) => {
			defined(resp.data.cs_unseen_count) && setCsUnseenCount(resp.data.cs_unseen_count);
			setCsStaffId(resp.data.cs_staff_id);
			setCsActiveUntil(resp.data.cs_active_until);
		}).catch((err) => {wa_user.last_cs_ping_at = 0});
	}

	useEffect(
		() => {
			if(!cs_staff_id) return;
			getByIds({"user_ids": [cs_staff_id]}).then((cache) => {
				setCsStaffUser(cache.users[cs_staff_id]);
			});
		}, 
		[cs_staff_id]
	);

	const mark_cs_inactive = () => {
		axios.post(
			`/api/admin/org/${org_id}/user/${user_id}`,
			{"action": "cs_inactive"}
		).then((resp) => {
			wa_user.last_cs_ping_at = 0
			if(defined(resp.data.cs_unseen_count)) setCsUnseenCount(resp.data.cs_unseen_count);
		});
	}

	const resolveChat = () => {
		if(ctx.is_resolving) return
		ctx.is_resolving = true;
		axios.post(
			`/api/admin/org/${org_id}/user/${user_id}`,
			{"action": "resolve"}
		).then((resp) => {
			setCsUnseenCount(resp.data.cs_unseen_count);
			onChatResolved && onChatResolved(resp.data.cs_unseen_count);
		}).finally(() => ctx.is_resolving = false);
	}
	
	const _onCsChatActivity = (session_data) => {
		if(!session_data.session._id.startsWith("wcs_")) return;
		ctx.is_cs_active = true;
		ping_cs_active();
		setCsActiveUntil(new Date().getTime() + MILLIS_IN_MINUTE);
		if(ctx.cs_active_timer) clearInterval(ctx.cs_active_timer);
		/* ping every 10 minutes */
		ctx.cs_active_timer = setInterval(() => ctx.is_cs_active && ping_cs_active(), MILLIS_IN_MINUTE);
	}

	const toggleBotActive = () => {
		let now_millis = new Date().getTime();
		if (cs_active_until > now_millis) {
			mark_cs_inactive();
			setCsActiveUntil(now_millis);
			ctx.is_cs_active = false;
		} else {
			ping_cs_active();
			setCsActiveUntil(now_millis + MILLIS_IN_MINUTE);
			ctx.is_cs_active = true;
		}  
	}

	useEffect(
		() => {
			const onCsChatTyping = (session_data, el) => _onCsChatActivity(session_data);
			const onCsMessageSent = (im, session_data) => _onCsChatActivity(session_data);
			broadcaster.add_event_listener("chat:typing", onCsChatTyping);
			broadcaster.add_event_listener("chat:message_sent", onCsMessageSent);
			broadcaster.add_event_listener("chat:cs_activity", _onCsChatActivity); 

			const onSessionUpdate = (im) => {
				if(!im.inbox_id === session_data.session._id) return
				if(
					(im._type === INBOX_MESSAGE_TYPE_SESSION_UPDATE || im._type === INBOX_MESSAGE_TYPE_SESSION_USER_UPDATE)
					&& im.data
				){
					defined(im.data.cs_unseen_count) && setCsUnseenCount(im.data.cs_unseen_count);
					im.data.cs_active_until && setCsActiveUntil(wa_user.cs_active_until = im.data.cs_active_until);
					im.data.cs_staff_id && setCsStaffId(wa_user.cs_staff_id= im.data.cs_staff_id);
				}
			}
			broadcaster.add_event_listener(`im:${session_data.session._id}`, onSessionUpdate);
			return () => {
				clearInterval(ctx.cs_active_timer);
				broadcaster.remove_event_listener("chat:typing", onCsChatTyping);
				broadcaster.remove_event_listener("chat:message_sent", onCsMessageSent);
				broadcaster.remove_event_listener("chat:cs_activity", _onCsChatActivity);
				broadcaster.remove_event_listener(`im:${session_data.session._id}`, onSessionUpdate);
			}
		}, []
	);

	useEffect(() => {ctx.is_cs_active && ping_cs_active()}, [is_page_visible]);


	const now_millis = new Date().getTime();
	const buttons = (
			<>
				{
					cs_unseen_count
					?   <div className='tw-px-2 tw-py-1 tw-bg-orange-500 tw-grow'
							onClick={resolveChat}
						>☐ Resolve Chat</div>
					:   org_user && <div className="tw-px-2 tw-grow tw-bg-black tw-py-1"
							onClick={
								() => Popup.sideSheet(
									<UserFullDetails org_id={org_id} user_id={user_id} />,
									{"style": {"zIndex": 50}}
								)
							}
						>👤 Manage</div>
				}
				<div className={`tw-px-2 tw-grow tw-py-1 tw-bg-blue-500 ${!cs_unseen_count ? 'tw-min-w-[30%]' : ''}`}
					onClick={() => toggleBotActive()}
				>
					{cs_active_until > now_millis ? "☐" : "✅"} BOT
				</div>
				{
					/*
						cs active leases the chat for 7 minutes and updates every 1.5 minutes
						if the chat was leased by another user less than 4 minutes ago, show force button
					*/
					cs_active_until > now_millis + 3 * MILLIS_IN_MINUTE && cs_staff_user && user && cs_staff_user._id !== user._id
					?   <div className='tw-bg-red-600 tw-flex tw-flex-row tw-clear-both tw-flex-grow tw-p-1'>
							<div className='tw-pl-2'>{cs_staff_user.name} is handling this chat</div>
							<div className='tw-px-4 tw-ml-auto tw-border tw-content-center' onClick={() => ping_cs_active(true)}>Force</div>
						</div> /* change it to tailwind */
					: null
				}
			</>
	);
	if(!wrapper) return buttons;
	return <div className='tw-text-sm tw-flex tw-flex-row tw-flex-wrap tw-bg-gray-500 tw-text-white tw-items-center tw-cursor-pointer'>
		{buttons}
	</div>
}


function UserFullDetails({org_id, user_id, org_user:_org_user}){

	const [tickets, setTickets] = useState(null);
	const [org_user, setOrgUser] = useState(null);
	const [tag_types] = useBroadcastedState("tag_types", {});
	const ctx = useRef({"tickets": []}).current;
	useEffect(
		() => {
			if(ctx.is_loading) return;
			ctx.is_loading = true;
			axios.post(`/api/admin/org/${org_id}/user/${user_id}`).then(
				(resp) => {
					resp.data.tickets && ctx.tickets.push(...resp.data.tickets);
					setOrgUser(resp.data.org_user);
					_org_user && resp.data.org_user 
						&& Object.assign(_org_user, resp.data.org_user); // update to latest data
					setTickets([...ctx.tickets]);
				}
			).finally(() => {ctx.is_loading = false});
		}, []
	);

	const doCreateTicket = () => {
		var popup = Popup.sideSheet(
			<div className='tw-p-2'>
				<div className='tw-text-lg tw-font-semibold tw-p-2'>Create New Ticket</div>
				<EditTicketData
					ticket={{"org_id": org_id, "user": org_user}}
					onUpdate={(ticket) => {
						ctx.tickets.unshift(ticket); // add this ticket to the top
						fetchUsersByIdsOnTickets([ticket]).then(() => setTickets([...ctx.tickets]));
						popup.close();
					}}
				/>
			</div>,
			{"style": {"zIndex": 50}}
		);
	}

	if(!org_user) return <LoadingView height={"400px"}/>

	return (
		<div className='tw-p-4'>
			{/* name */}
			<div className='tw-mb-4 tw-text-xl'>{org_user.name}</div>
			<div className='tw-space-y-2'>
				{org_user.phone_number && <div><FontAwesomeIcon icon={faPhone} /> {org_user.phone_number}</div>}
				{org_user.email_id && <div><FontAwesomeIcon icon={faEnvelope} /> {org_user.email_id}</div>}
				<ShowTagsWithValues tags={org_user.tags} tag_types={tag_types} />
			</div>
			{/*tickets */}
			<div className='tw-text-xl tw-mt-4 tw-border-t tw-py-2 tw-mb-4 '>
				<div className='tw-flex tw-flex-row'>
					<div>Tickets</div>
					<div className='tw-ml-auto tw-flex tw-gap-4' onClick={doCreateTicket}>
						<button className='btn-primary tw-text-xs tw-shrink-0'>+ Create Ticket</button>
					</div>
				</div>
			</div>
			{
				tickets?.length
				?	<TicketsList tickets={tickets} />
				:	<EmptyView height={"200px"} title="No tickets" />
			}
		</div>
	);
}



export { OrgUsersPage, OrgUserChatSupportButtons, UserFullDetails };
