import { useState } from "react";
import {Link, useNavigate } from "react-router-dom";
import { Popup } from "./ui/popups"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope, faEdit } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";
import { useEffect } from "react";
import axios from "axios";
import { broadcaster, useBroadcastedState, useLocalStorageState } from "./utils/events";
import { getRandomColor, useRerender } from "./utils/common";
import { ThumbnailFileView } from "./ui/misc";
import { LoadingView } from "./ui/status";
import PROFILE_GENERIC_AVATAR from "./images/avatar.png"
import { FileUploader } from "./file_uploader";
import { useCurrentUser } from "./app";
import { GenericException } from "./ui/errors";
import { UserWallet } from "./payments";
import { connectToAllsparkRt } from "./allsparkrt";


function RegistrationRequirements({ctx}){
    const rerender = useRerender();
    return <>
        <h1 className="w3-border-bottom w3-border-white">Login to continue</h1>
        {
            ctx.require?.need_email_id_or_phone_number
            ?   <>
                    <div className="w3-padding-8">Enter your Email/Phone Number</div>
                    <div className='w3-flex w3-flex-vcenter'>
                        {   /* show if it's a number */
                            ctx.data.email_id_or_phone_number?.match(/^\+?[ \d]+/)
                            ?   <select defaultValue={ctx.data?.phone_country_code || "31"}
                                    className="w3-select w3-col s3 w3-no-outline w3-white w3-border-none w3-padding-sides-8 w3-center w3-border-right"
                                    onChange={(evt) => ctx.data.phone_country_code= evt.target.value}
                                >
                                    <option value="31">NL (+31)</option>
                                    <option value="1">US (+1)</option>
                                    <option value="91">IN (+91)</option>
                                    <option value="44">UK (+44)</option>
                                    <option value="33">FR (+33)</option>
                                    <option value="49">DE (+49)</option>
                                    <option value="34">ES (+34)</option>
                                    <option value="39">IT (+39)</option>
                                    <option value="86">CN (+86)</option>
                                </select>
                            :   null
                        }                        
                        <input defaultValue={ctx.data.email_id_or_phone_number} className='w3-input w3-border-none w3-focus-no-outline'
                            onChange={
                                (evt) => {
                                    ctx.data.email_id_or_phone_number= evt.target.value;
                                    rerender();
                                }
                            }
                        />
                    </div> 
                </>
            : null
        }
        {
            ctx.require?.need_user_name
            ? <>
                <div className="w3-padding-8">Please enter your name</div>
                <input type='text' defaultValue="" className='w3-input'
                placeholder="Your name"
                onChange={(evt) => ctx.data.user_name= evt.target.value}/>
                </>
            : null
        }
        {
            ctx.require?.need_phone_number
            ?   <>
                    <div className="w3-padding-8">Please enter your phone number</div>
                    <div className='w3-flex w3-border w3-flex-vcenter'>
                        <select defaultValue={ctx.defaults?.phone_country_code || ctx.data?.phone_country_code || "31"}
                            className="w3-select w3-col s3 w3-no-outline w3-white w3-border-none w3-padding-sides-8 w3-center w3-border-right"
                            onChange={(evt) => ctx.data.phone_country_code= evt.target.value}
                        >
                            <option value="31">NL (+31)</option>
                            <option value="1">US (+1)</option>
                            <option value="91">IN (+91)</option>
                            <option value="44">UK (+44)</option>
                            <option value="33">FR (+33)</option>
                            <option value="49">DE (+49)</option>
                            <option value="34">ES (+34)</option>
                            <option value="39">IT (+39)</option>
                            <option value="86">CN (+86)</option>
                        </select>
                        <input defaultValue={ctx.defaults?.phone_number || ctx.data.phone_number || ""} className='w3-input w3-col s9 w3-border-none w3-focus-no-outline'
                            placeholder="Phone Number"
                            onChange={(evt) => ctx.data.phone_number= evt.target.value}
                        />
                    </div> 
                </>
            : null
        }
        {
            ctx.require?.need_email_id
            ?   <>
                    <div className="w3-padding-8">Please enter your Email Address</div>
                    <div className='w3-flex w3-border w3-flex-vcenter'>
                        <input defaultValue={ctx.defaults?.email_id || ctx.data.email_id || ""}
                        className='w3-input w3-border-none w3-focus-no-outline'
                            placeholder="Email Address"
                            onChange={(evt) => ctx.data.email_id= evt.target.value}
                        />
                    </div> 
                </>
            : null
        }
        {
            ctx.require?.need_otp
            ?   <>
                    <div className="w3-padding-8">We sent an OTP to you</div>
                    <input type='password' defaultValue="" className='w3-input'
                        onChange={(evt) => ctx.data.otp= evt.target.value}
                        style={{"letterSpacing": "40px"}}
                    />
                </>
            : null
        }
    </>
}

function Login({afterLogin, className}){
    const ctx = useRef({"data": {}}).current;
    const [user, setUser] = useBroadcastedState("user_updated");
    const rerender = useRerender();
    const doLogin = () => {
        axios.post("/api/user/login", ctx.data).then(
            (resp) => {
                if(resp.data.user){
                    setUser(resp.data.user);
                    resp.data.allspark_auth_key && connectToAllsparkRt(resp.data.allspark_auth_key);
                    afterLogin && afterLogin(resp.data.user);
                    return;
                }
                resp.data.require !== undefined && (ctx.require= resp.data.require);
                ctx.errors = resp.data.errors;
                /* keep updating checkout data from backend */
                resp.data.data && (ctx.data= {...(ctx.data || {}), ...resp.data.data});
                rerender()
            }
        );
    }

    useEffect(() => {!user && doLogin()}, [user]);
    if(user){
        return <h3>You are logged in as <b>{user.name}</b></h3>
    }
    return <div className={`w3-list ${className || ''} w3-light-grey w3-padding`} style={{"maxWidth": "800px"}}>
        <RegistrationRequirements ctx={ctx} />        
        <div className="w3-padding-topbottom-8 w3-list-horizontal-8 w3-flex-row">
            {ctx.errors && <GenericException ex={ctx.errors} />}
            <div className="w3-margin-left-auto">
                <button className="w3-button w3-green w3-2px-shadow" onClick={doLogin}>Next</button>
            </div>
        </div>
    </div>;
}


function loginUserInPopup(){
    var resolver = null;
    var ret = new Promise((resolve, reject) => resolver = resolve);
    var popup = null;
    const afterLogin = (user) => {
        resolver(user);
        broadcaster.broadcast_event("user:loggedin", user);
        popup.close();
    }
    popup = Popup.show(
        "", 
        <Login afterLogin={afterLogin} className="w3-padding w3-content" />,
        {cb: (v) => v === 0 && resolver(null)} // if user closes the popup, resolve null
    );
    return ret;
}

function EditProfilePage(){
    const user = useCurrentUser();
    const ctx = useRef({
        "data": {},
        "require": {},
        "defaults": {},
    }).current;
    const navigate = useNavigate();
    const rerender = useRerender();

    useEffect(() => {
        if(user){
            ctx.require["need_phone_number"] = !!user?.phone_number;
            ctx.require["need_email_id"] = !!user?.email_id;
            ctx.defaults["phone_number"] = user?.phone_number;
            ctx.defaults["email_id"] = user?.email_id;
            rerender();
        }
    }, [user]);


    const doUpdateProfile = () => {
        axios.post("/api/user/profile?action=update", ctx.data).then(
            (resp) => {
                if(resp.data.user){
                    navigate("/profile");
                    return;
                }
                resp.data.require !== undefined && (ctx.require= resp.data.require);
                ctx.errors = resp.data.errors;
                /* keep updating checkout data from backend */
                resp.data.data && (ctx.data= {...(ctx.data || {}), ...resp.data.data});
                rerender();
            }
        );
    }
    if(user === undefined) return <LoadingView height={"300px"} />
    if(user === null) return <Login />;

    return (
        <div className="w3-content w3-padding">
            <div className="w3-xlarge w3-bold w3-margin-bottom">Edit Profile</div>
            <div className="w3-flex" style={{"maxWidth": "600px"}}>
                <div className="w3-col s4">
                    <FileUploader
                        file={user.image}
                        onFilesUpdated={(files) => {
                            ctx.data.image = files?.[0];
                        }} 
                    />
                </div>
                <div className="w3-flex-grow-s1 w3-margin-left w3-list">
                    <div>
                        <div className="w3-margin-bottom-8">Update Name</div>
                        <input className="w3-input" defaultValue={user.name} 
                            placeholder="Your name"
                            onChange={(evt) => ctx.data.name = evt.target.value}
                        />
                    </div>
                    <RegistrationRequirements ctx={ctx} />
                </div>
            </div>
            <div className="w3-margin-top-32 w3-list-horizontal-16">
                <div className="w3-button w3-red w3-round-xlarge"
                    onClick={() => navigate("/profile")}
                >Cancel</div>

                <div className="w3-button w3-black w3-round-xlarge"
                    onClick={doUpdateProfile}
                >Update</div>
            </div>

        </div>
    );
}


function NeedUserView({user, setUser}){
    if(user === undefined){
        return <LoadingView height={"300px"} title={"Loading.."}/>
    }
    if(user === null){
        return <div className="w3-content w3-padding">
            <Login afterLogin={(user) => setUser?.(user)} />
        </div>
    }
}

function UserProfilePage(){

    const [user, setUser] = useBroadcastedState("user_updated");
    const [user_wallets, setUserWallets] = useState(null);
    const navigate = useNavigate();

    useEffect(
        () => {
            axios.get("/api/user/profile").then(
                (resp) => {
                    setUser(resp.data.user || null);
                    setUserWallets(resp.data.user_wallets);
                }
            );
        },
        []
    );   

    const doLogout = () => {
        axios.post("/api/user/logout").then((resp) => {
            window.localStorage?.removeItem("allspark_auth_key");
            setUser(null);
            window.location.href = "/";
        });
    }
        
    if(!user) return <NeedUserView user={user} setUser={setUser}/>;
        
    return (
        <div className="w3-padding">
            <div className="w3-bold w3-margin-bottom w3-flex w3-flex-vcenter">
                <h1>Your Profile</h1>
                <FontAwesomeIcon icon={faEdit} className="w3-margin-left"
                    onClick={() => navigate("/edit/profile")} 
                />
            </div>
            <div className="w3-flex">
                {
                    <div className="w3-col s4">
                        {
                            user.image 
                            ?   <ThumbnailFileView file={user.image} />
                            :   <img src={PROFILE_GENERIC_AVATAR} alt="avatar" className="w3-image" />
                        }
                    </div>
                }
                <div className="w3-flex-grow-s1 w3-margin-left w3-list">
                    <h3>{user.name}</h3>
                    {
                        user.phone_number
                        ?   <div className="w3-flex w3-flex-vcenter">
                                <FontAwesomeIcon icon={faPhone} />
                                <div className="w3-margin-left">{user.phone_number}</div>
                            </div> 
                        : null
                    }
                    {
                        user.email_id
                        ?   <div className="w3-flex w3-flex-vcenter">
                                <FontAwesomeIcon icon={faEnvelope} />
                                <div className="w3-margin-left">{user.email_id}</div>
                            </div> 
                        : null
                    }                    
                </div>
            </div>
            {
                user_wallets?.length
                ?   <div className="w3-margin-top">
                        <h1 className="w3-margin-bottom">Your Wallets</h1>
                        <div className="w3-list">
                            {
                                user_wallets.map(
                                    (wallet) => <UserWallet wallet={wallet}
                                        key={`${wallet.wallet_for}_${wallet.wallet_curreny}`}
                                    />
                                )
                            }
                        </div>
                    </div>
                :   <div className="w3-padding w3-border w3-border-round w3-bold w3-text-grey w3-margin-topbottom-32">
                        You don't have any Wallet/Credits
                    </div>
            }
            <div className='w3-flex w3-list-horizontal w3-margin-topbottom-32'>
                <div className='w3-button w3-red' onClick={doLogout}>Logout</div>
            </div>
        </div>
    )
}

function UserSideMenuItem(){
    const user = useCurrentUser();
    return (
        user 
        ?   <div className='w3-center'>
                <img src={user.image?.url || user.image || PROFILE_GENERIC_AVATAR}
                    className='w3-circle w3-border w3-border-yellow w3-margin-right-16'
                    style={{"width": "50px"}} 
                    alt={user.name}
                />
                <Link to={`/profile`} className='w3-decoration-none'><h2>{user.name}</h2></Link>
                <hr />
            </div>
        :   <Link className='w3-bar-item w3-button w3-margin-top-64'
                onClick={(evt) => {evt.stopPropagation();loginUserInPopup()}}
            >Login</Link>
    )
}


export {loginUserInPopup, Login, UserProfilePage, EditProfilePage, NeedUserView, UserSideMenuItem}
