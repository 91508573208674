import React, { useEffect, useRef, useState} from 'react';
import ReactSwipe from 'react-swipe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faPen, faTrash, faX, faArrowUp, faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import { swipeDetect } from '../utils/detect';
import { getRandomColor, isAudio, isImage, isPdf, isVideo, useRerender, useRerenderWithValue } from '../utils/common';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import PROFILE_GENERIC_AVATAR from "../images/avatar.png"
import { IS_MOBILE } from '../constants';
import { checkElemPositioningType, getUKey, Offset, scrollLeft, scrollTop } from './utils';
import { addScrollListener, removeScrollLister, getScrollParent } from '../utils/scrolling';
import { Popup } from './popups';
import { getByIds } from '../get_by_ids';
import { getKeyPath, hashCode, isFunction, isString, last, setKeyPath } from '../utils';

const _colors = ["red", "deep-orange", "purple", "teal", "yellow"];
var CSS_COLOR = (str) => {    
    return _colors[hashCode(str) % _colors.length];
}

function ShortImageAndDescription({images, title, description, tag, action_button}){
    return (
        <div className="w3-flex-row w3-list-horizontal">
            {
                images && images.length > 1
                ?   <ReactSwipe className="w3-col s3">
                        {images.map((img, i) => <img key={i} src={img.url || img} className="w3-image" /> )}
                    </ReactSwipe>
                :   <div className="w3-col s3">
                        {images?.[0] && <img src={images[0].url || images[0]} className="w3-image" />}
                    </div>
            }
            
            <div className="w3-flex-grow-s1">
                <div className="w3-bold">{title}</div>
                {
                    description
                    ?   <div className="w3-text-grey w3-small">{description}</div>
                    :   null
                }
                {
                    tag 
                    ?   <div className={
                            `w3-tag w3-${CSS_COLOR(tag + 'xxx')}`
                        }>{tag}</div> 
                    :   null
                }
            </div>
            {
                action_button
                ?   <div className="w3-center">
                        <a className="w3-decoration-none w3-button w3-2px-shadow w3-round"
                            href={action_button.url}
                        >
                            {action_button.title || title.substr(50)}
                        </a>
                        {
                            action_button.description
                            ?  <div className="w3-small">{action_button.description}</div>  
                            :   null
                        }
                    </div>
                : null
            }
        </div>
    )
}

function HorizontalUsersList({user_ids}){
    const [users, setUsers] = useState(null);
    useEffect(
        () => {
            if(!user_ids?.length) return;
            getByIds({"user_ids": user_ids}).then((data) => {
                setUsers(user_ids.map((user_id) => data.users[user_id]));
            });
        }, [user_ids]
    );
    if(!users) return null;
    return <div className='w3-flex-row-wrap'>
        {users.map((user, i) => <UserShortBadge user={user} />)}            
    </div>
}

function VerticalUsersList({user_ids}){
    const [users, setUsers] = useState(null);
    useEffect(
        () => {
            if(!user_ids?.length) return;
            getByIds({"user_ids": user_ids}).then((data) => {
                setUsers(user_ids.map((user_id) => data.users[user_id]));
            });
        }, [user_ids]
    );
    if(!users) return null;
    return <div className='w3-flex-col w3-list w3-list-bordered'>
        {
            users.map((user, i) => <div className='w3-flex-row'>
                    <div className='w3-padding-4'>
                        <img className="w3-circle w3-image" style={{"height": "25px", "width":"25px"}} 
                            src={user.image?.url || user.image || PROFILE_GENERIC_AVATAR}
                            alt={user.name}
                            title={user.name}
                        />
                    </div>
                    <div className='w3-padding-4'>{user.name}</div>
                </div>
            )
        }            
    </div>
}

function CrammedUsersList({user_ids, options}){
    const [users, setUsers] = useState(null);
    const [highlighed_user_index, setHighlightedUserIndex] = useState(0);
    useEffect(
        () => {
            if(!user_ids?.length) return;
            getByIds({"user_ids": user_ids}).then((data) => {
                setUsers(user_ids.map((user_id) => data.users[user_id]));
            });
        }, [user_ids]
    );
    useEffect(
        () => {
            /* just highlight users one by one  */
            if(!users?.length) return;
            var i = 0;
            const interval = setInterval(
                () => setHighlightedUserIndex(++i % users.length),
                2000
            );
            return () => clearInterval(interval);
        }, [users]
    );

    if(!users?.length) return null;
    /* crammed list of user images */
    return <div>
        <div  className='w3-relative' style={{"minHeight": "27px"}}>
            {
                users.map((user, i) => 
                    <div className="w3-absolute w3-white w3-border w3-circle"
                        key={user._id}
                        style={{"padding":"1px", "left": (20 * (i++)) + "px"}}
                    >
                        <img className="w3-image w3-circle" style={{"height": "25px", "width":"25px"}}
                            src={user.image?.url || user.image || PROFILE_GENERIC_AVATAR}
                            alt={user.name}
                            title={user.name}
                        />
                    </div>
                )
            }
        </div>
        <div className='w3-padding-topbottom-4 w3-text-grey w3-animate-opacity' key={highlighed_user_index}>
            {users[highlighed_user_index].name}
        </div>
    </div>
}

function RoundUserImage({user}){
    if(!user) return null;
    let user_image = user.image?.url || user.image;
    return (
        <div className="w3-padding-2 w3-circle w3-border w3-overflow-hidden w3-grey w3-text-white"
            style={{"width": "40px", "height":"40px"}} 
        >
            <div className="w3-expand w3-flex w3-flex-vcenter w3-flex-hcenter w3-xlarge">
                {
                    user_image
                    ?   <img className="w3-image" src={user_image}/>
                    :   user.name?.slice(0,1)
                }
            </div>
        </div>
    );
}

function UserBadge({user}){
    return (
        <div key={user._id} className="w3-flex">
            <RoundUserImage user={user} />
            <div className='w3-flex-grow-s1 w3-margin-left-8'>
                <div className="w3-capitalize">{user.name}</div>
                <div className='w3-text-grey w3-tiny'>{user.phone_number || user.email_id}</div>
            </div>
        </div>
    );
}



function UserShortBadge({user, tag}){
    return (
        <div className="w3-padding-4 w3-inline w3-center" style={{"maxWidth": "60px"}}>
            <div className="w3-center w3-padding-topbottom-4">
                <div className="w3-relative w3-inline w3-padding-2 w3-border w3-circle">
                    <img className="w3-circle w3-image"
                        src={user.image ? user.image.url || user.image : PROFILE_GENERIC_AVATAR}
                        style={{"width": "30px", "height":"30px"}}
                    />
                </div>
            </div>
            <div className="w3-center w3-padding-bottom-4 w3-super-tiny">
                <div className="w3-text-grey w3-bold w3-capitalize">{user.name}</div>
                {
                    tag 
                    ?   <div className={
                            `w3-tag w3-${CSS_COLOR(tag + 'xxx')}`
                        }>{tag}</div> 
                    :   null
                }
            </div>
        </div>
    )
}


/* table input */
function RowInput({row, nCols, onAction, placeholders, input_types}){
    for(let i=row.length;i<nCols;i++){
        row.push("");
    }
    return <div className="w3-flex-row w3-list-horizontal-8">
        {
            row.map(
                (val, i) => {
                    return (
                        <div key={i}>
                            <input type={input_types ? input_types[i] : "text"} className="w3-input"
                                onChange={(evt) => {row[i] = evt.target.value; onAction("change");}}
                                placeholder={placeholders[i]}
                                defaultValue={val}
                            />
                        </div>
                    )
                }
            )
        }
        <div className="w3-col s2 w3-padding-8 w3-flex w3-list-horizontal-16">
            <FontAwesomeIcon icon={faTrash} onClick={()=> {onAction("delete", row)}} />
            <FontAwesomeIcon icon={faArrowUp} onClick={()=> {onAction("moveup", row)}} />
        </div>
    </div>
}

function SimpleTableInput({rows:_rows, nCols, placeholders, inputType: input_types, onAction}){
    const [rows, setRows] = useState(_rows);
    placeholders = placeholders || [];
    nCols = nCols || (_rows && _rows.length && _rows[0].length) || 2;
    return (
        <div className="w3-row w3-border w3-padding-8">
            {
                rows.map(
                    (row, i) => <RowInput
                        row={row} nCols={nCols} key={getUKey(row)}
                        placeholders={placeholders}
                        onAction={(action, row)=>{
                            if(action === "delete"){
                                _rows.splice(_rows.indexOf(row), 1);
                                setRows([..._rows]);
                            } else if(action == "moveup"){
                                let index = _rows.indexOf(row);
                                if(index > 0){
                                    _rows.splice(index, 1);
                                    _rows.splice(index - 1, 0, row);
                                    setRows([..._rows]);
                                }
                            } 
                            onAction?.(action, row, _rows);
                        }}
                        input_types={input_types}
                    />
                )
            }
            <div className="w3-right-align w3-padding-8">
                <div className="w3-button w3-green w3-small"
                    onClick={() => {
                        _rows.push(new Array(nCols).fill(""));
                        setRows([..._rows]);
                        onAction?.("add", _rows[_rows.length - 1], _rows);
                    }}
                >Add</div>
            </div>
        </div>
    );
}

/**
 * DynamicInput
 * A component for rendering different types of input fields based on the column properties.
 *
 * @param {any} defaultValue - The initial value of the input field.
 * @param {function} onChange - A function to handle changes in the input field.
 * @param {Object} col - The column properties.
 * @param {string} col.type - The type of the input field. It can be 'textarea', 'boolean', 'select', 'text', or 'number'.
 * @param {Array} col.list - The list of options for the 'select' type.
 * @param {string} col.placeholder - The placeholder text for the input field.
 * @param {Object} col.props - Additional properties for the input field.
 *
 * @returns {JSX.Element} - The input field based on the column properties.
 */function DynamicInput({defaultValue, onChange, col}) {
    const { type, list, placeholder} = col;
    if (type === 'textarea') {
        return <textarea defaultValue={defaultValue} {...col.props || {}}
            onChange={(evt) => onChange(evt.target.value)} className='w3-input' placeholder={placeholder} />
    } else if (type === 'boolean') {
        return <input type='checkbox' defaultChecked={defaultValue}
            onChange={(evt) => onChange(evt.target.checked)} className='w3-input'
        />
    } else if (type === 'select' && list?.length) {
        return <select defaultValue={defaultValue}
            onChange={(evt) => onChange(evt.target.value)} className='w3-input' placeholder={placeholder}>
            {list.map((item) => {
                return typeof item === 'object'
                    ? <option key={item.value} value={item.value}>{item.label}</option>
                    : <option key={item} value={item}>{item}</option>
            })}
        </select>
    } else if (type === 'text' || type == 'number') {
        return <input type={type} defaultValue={defaultValue} {...col.props || {}}
            onChange={(evt) => onChange(evt.target.value)} className='w3-input' placeholder={placeholder} />
    }
    return null;
}

/**
 * A component for displaying and editing tabular data.
 *
 * @param {Object} props - The properties for the component.
 * @param {Array} props.rows - The initial rows of data.
 * @param {Array} props.cols - The columns of data. Each column is an object with a title and an id.
 *
 * @returns {JSX.Element} - The TableInput component.
 */
function TableInput({rows:_rows, cols}){
    const [rows, setRows] = useState(_rows);

    const handleChange = (index, _id) => {
        return (value) => {
            rows[index][_id] = _rows[index][_id] = value;
        }
    }

    return (
        <>
        <table className='w3-table w3-table-all w3-cell-bordered'>
            <thead>
                <tr>
                    {cols.map(col => (
                        <th key={`th_${col._id}`}>{col.title}</th>
                    ))}
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {rows.map((row, i) => (
                    <tr key={getUKey(row)}>
                        {cols.map((col) => (
                            <td key={`td_${col._id}`}>
                                <DynamicInput col={col} defaultValue={row[col._id]} onChange={handleChange(i, col._id)} />
                            </td>
                        ))}
                        <td>
                            <FontAwesomeIcon icon={faTrash} onClick={()=> {_rows.splice(i, 1); setRows([..._rows]);}} />
                            {i === 0 ? null : <FontAwesomeIcon icon={faArrowUp} onClick={()=> {_rows.splice(i, 1); _rows.splice(i - 1, 0, row); setRows([..._rows]);}} className="w3-margin-left w3-text-blue" />}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        <div className="w3-right-align w3-padding-8">
                <div className="w3-button w3-green w3-small"
                    onClick={() => {
                        _rows.push({});
                        setRows([..._rows]);
                    }}
                >Add</div>
            </div>
        </>
    );
}


/* Editable attr text field, with a checkbox to enable/disable */
function NamedCheckboxAttribute({attrs:_attrs, _key, title, onAttrsChanged, default_value, placeholder}){
    const [attrs, setAttrs] = useState(_attrs || {});
    return (
        <label className="w3-flex w3-flex-vcenter w3-row">
            <input type="checkbox"
                checked={!!attrs[_key]}
                className="w3-check"
                onChange={
                    (evt) => {
                        if(!evt.target.checked){
                            delete attrs[_key];
                        }
                        else{
                            attrs[_key] = default_value;
                        }
                        let _attrs = {...attrs};
                        setAttrs(_attrs);
                        onAttrsChanged(_attrs);
                    }
                }
            />
            <span className="w3-padding-sides-8">{title}</span>
            {
                attrs[_key]
                ?   <input type="text" name={_key} className="w3-input-inline" placeholder={placeholder || "Name"} 
                        onChange={
                            (evt) => {
                                attrs[_key] = evt.target.value;
                                let _attrs = {...attrs};
                                setAttrs(_attrs);
                                onAttrsChanged(_attrs);
                            }
                        }
                    />
                :   null
            }
        </label>
    );
}

/* Display As pages navigatable  */
function PageIndicator({page, className}){
    return (
        <div className={"w3-flex-row-wrap w3-flex-hcenter w3-flex-vcenter w3-array-margin w3-pointer w3-border " + (className || "")}
            style={{"height": "40px", "width": "40px"}}
            page={page}
        >
            <div className='w3-padding-4'>{page}</div>
        </div>
    )
}
function PagesSelection({page:_page, total_pages, onPageSelected}){
    const [page, setPage] = useState(_page);
    const [num_right_pages, setRightNumPages] = useState(5);
    const [num_left_pages, setLeftNumPages] = useState(5);
    
    const prev_pages = [];
    for(let i=Math.max(1, page - num_left_pages);i<page;i++){
        prev_pages.push(i);
    }
    // next pages
    const next_pages = [];
    for(let i=page + 1;i<=Math.min(total_pages, page + num_right_pages);i++){
        next_pages.push(i);
    }

    const handlePageSelect = (evt) => {
        let parent = evt.target;
        while(parent && parent != evt.currentTarget){
            let page_attr = parent.getAttribute("page");
            if(page_attr){
                let selected_page = parseInt(page_attr);
                setPage(selected_page);
                onPageSelected(selected_page);
                return;
            }
            parent = parent.parentElement;
        };    
    }

    return (
        <div className='w3-flex w3-flex-row-wrap' onClick={handlePageSelect}>
            {
                prev_pages.length && prev_pages[0] != 1
                ?   <PageIndicator page={1} />
                :   null
            }           
            {
                prev_pages.length && prev_pages[0] > 2
                ?  <div className='w3-inline' onClick={() => {setLeftNumPages(num_left_pages + 5)}}>.....</div>
                :   null
            }
            {/*previous pages*/}
            {
                prev_pages.map(
                    (i)=> <PageIndicator page={i} key={i} />
                )
            }
            {/*current page*/}
            <PageIndicator page={page} className="w3-border-green" />
            {
                next_pages.map(
                    (i)=> <PageIndicator page={i} key={i} />
                )
            }
            {
                next_pages.length && next_pages[next_pages.length - 1] < total_pages
                ?  <div className='w3-inline' onClick={() => {setRightNumPages(num_right_pages + 5)}}>.....</div>
                :   null
            }
            {
                next_pages.length && next_pages[next_pages.length - 1] != total_pages
                ?   <PageIndicator page={total_pages} />
                :   null
            }
        </div>
    )
}


/* downloaded files  */
export function ThumbnailFileView({file, className, onClick}){
    let file_url = file.thumbnail || file.url || file;
    if(isImage(file)){
        return <img
            src={file_url}
            alt="Image Unavailable"
            className={`w3-image-inline w3-bounded w3-pointer ${className || ''}`}
            onClick={() => onClick ? onClick() : Popup.show("Image", <div className='w3-flex w3-flex-hcenter'><PinchZoomableImage file_url={file_url} /></div>)}
        />
    } 
    if(isAudio(file)){
        return (
            <audio controls className={`w3-bounded ${className || ''}`} preload="none">
                <source src={file_url} type={isAudio(file_url)} />
            </audio>
        )
    }
    const is_video_mime_type = isVideo(file)
    if(is_video_mime_type){
        return (
            <video style={{"maxWidth": "100%"}} className='w3-image-inline w3-bounded w3-pointer' controls>
                <source src={file_url} type={is_video_mime_type} />
                Your browser does not support the video tag.
            </video>
        ); 
    }
    if(isPdf(file)) {
        return (
            <div className='w3-pointer' onClick={() => window.open(file_url, '_blank')}><object data={file_url} className='w3-image-inline w3-bounded' style={{pointerEvents: 'none'}} /></div>
        ); 
    }
    return  (
        <div style={{"height": "100px", "maxWidth": "200px"}} className={`w3-relative ${className || ''}`}>
            <div className="w3-display-middle">
                <a href={file_url} target="_blank" rel="noreferrer">
                    Download
                </a>
            </div>
        </div>
    );
}

export function PinchZoomableImage({file_url}){
    return (
        <TransformWrapper doubleClick={{"disabled": IS_MOBILE}}>
            <TransformComponent>
                <img alt={file_url} src={file_url} style={{"maxHeight": "500px", "maxWidth": "100%"}}/>
            </TransformComponent>
        </TransformWrapper>  
    );
}
export function FileView({file}){
    let file_url = file.thumbnail || file.url || file;
    if(isImage(file)){
        return <PinchZoomableImage file_url={file_url}/>;
    }
    if(isAudio(file)){
        return (
            <audio controls class="w3-bounded" preload="none">
                <source src={file} type={`audio/${isAudio(file)}`} />
            </audio>
        )
    }
    const is_video_mime_type = isVideo(file);
    if(is_video_mime_type){
        return <video style={{"maxHeight": "300px", "maxWidth": "100%"}} autoPlay>
            <source src={file_url} type={is_video_mime_type} />
            Your browser does not support the video tag.
        </video>
    }
    return  (
        <div style={{"height": "300px"}} className="w3-relative">
            <div className="w3-display-middle">
                <a href={file_url} target="_blank" rel="noreferrer">
                    Download
                </a>
            </div>
        </div>
    );
}

/* displays a gallery view of given files */
export function Gallery({files, className}){

    const [current_file, setCurrentFile] = useState(0);

    const displayFile = (evt) => {  // delegate
        let i = evt.target.parentElement.getAttribute("i");
        if(!i) return;
        setCurrentFile(parseInt(i))
    }

    const displayed_file = useRef();
    useEffect(
        () => {
            if(!files?.length) return;
            return swipeDetect(
                displayed_file.current,
                (direction) => {
                    let next_file = Math.min(
                        Math.max(
                            0, current_file + (direction === "left" ? -1 : 1)
                        ),
                        files.length - 1
                    )
                    setCurrentFile(next_file)
                }
            );     
        },
        [displayed_file, current_file, files?.length]
    );

    if(!files?.length) return null;
    const file = files[current_file];
    return (
        <div className={`w3-row ${className || ""}`}>
            <div className='w3-flex w3-flex-hcenter w3-margin-bottom-8' ref={displayed_file}>
                <FileView file={file} />
            </div>
            {
                files.length > 1
                ?   <div className='w3-row w3-flex w3-flex-row-wrap w3-flex-hcenter' onClick={displayFile}>
                        {
                            files.map(
                                (file, i) => 
                                    <div className='w3-margin-4 w3-center w3-border w3-relative w3-overflow-hidden'
                                        style={{"width": "100px", "height": "100px"}}
                                        i={i}
                                        key={file.thumbnail || file.url || file}
                                    >
                                        <ThumbnailFileView file={file} onClick={() => setCurrentFile(i)}/>
                                    </div>
                            )
                        }
                    </div>
                :   null
            }
        </div>
    );
}

/* A tooltip components on a element(target) */
function ToolTip({target, children, ctx, options}){

    const _tooltip = useRef();
    const [_style, setStyle] = useState({});
    const [_class_name, setClassName] = useState("");

    const [_render, rerender] = useRerenderWithValue();     
    ctx.rerender = rerender;
    options = options || {};

    useEffect(
        () => {
            let tooltip = _tooltip.current;

            let target_top = Offset(target).top - scrollTop(window);
            let target_left = Offset(target).left - scrollLeft(window);

            let style = {
                "zIndex": 50,
                "position": "fixed",
                "maxWidth": window.innerWidth - 60,
                "minWidth": "100px",
                "minHeight": "30px"
            };

            let class_name = "";
            let target_width = target.offsetWidth || 10;
            let target_height = target.offsetHeight || 20;
            let pos_left = target_left + ( target_width / 2 ) - ( tooltip.offsetWidth / 2 );
            let pos_top  = target_top - tooltip.offsetHeight - 20;
            // let pos_bottom = target_top + target_height + tooltip.offsetHeight + 20;
            let _right = "unset"; 
            let _bottom = "unset";
            let _top = pos_top + 10;
            let _left = pos_left;
        
            if(pos_left < 0){
                pos_left = target_left + target_width / 2 - 20;
                _left = pos_left;
                _right = 'unset';
                class_name +=  ' tleft ';
            }
            else{
                class_name.replace('tleft', "");
            }
            if( pos_left + tooltip.offsetWidth > window.innerWidth ){
                class_name += ' tright';
                _right = window.innerWidth - target_left - target_width / 2 - 20;
                _left = "unset";
            }
            else{
                class_name.replace('tright', "");
            }
            
            let bottom_free_space = window.innerHeight - (target_top + target_height);
            let top_free_space = target_top;
            
            let hang_down = top_free_space < bottom_free_space;
            if(hang_down){  
                _top  = target_top + target_height + 10;
                class_name.replace(' tbottom', "");
                class_name += ' ttop';
            }
            else{
                class_name.replace('ttop', "");
                class_name += ' tbottom';
                _top = "unset";
                _bottom  = window.innerHeight - target_top + 10;
            }

            Object.assign(style,  {left: _left, top: _top, bottom: _bottom, right: _right });
            setClassName(class_name);
            setStyle(style);
        }, [_render, _tooltip.current]
    );

        
    useEffect(
        () => {
            const container = getScrollParent(target);
            /* if clicked outside of this tooltip close this */
            const closeTooltip = (evt) => {
                const is_click_inside = _tooltip.current?.contains(evt.target);
                if(!is_click_inside && options.no_auto_close) return;
                if (is_click_inside && options.multi_clickable ) return;
                ctx.close && ctx.close()
            };
            document.body.addEventListener("click", closeTooltip);

            /* if the container was scrolled, we reset the position of the tooltip */
            const cbs = {
                "on_scroll_stop": () => {rerender();}
            }
            addScrollListener(container, cbs);
            /* cleanup */
            return () => {
                removeScrollLister(container, cbs);
                document.body.removeEventListener("click", closeTooltip);
            }
        },
        []
    );
    
    return (
        <div className={`w3-round-large w3-shadow w3-pointer-tooltip ${_class_name}`}
            style={_style} ref={_tooltip}
        >
            {children}
        </div>
    );
}


/* Share Button */
const _file_bytes_cache = {};

function Share({title, description, link, file_url, cb, children, className}){
    title = title || "";
    description = description || "";

    var share_text = (title || "");
    if(description){
        share_text += ("\n" + description);
    }

    const [status_text, setStatusText] = useState(null);

    useEffect(
        () => {
            const timer = setTimeout(() => {
                setStatusText(null);
            }, 1000);
            return () => clearTimeout(timer);
        },
        [status_text]
    );

    var after_file_downloaded = function(file_bytes) {
        let mime_type = null;
        let file_name = null;
        let file_url_obj = new URL(file_url, window.location.href);
        if(file_url_obj.pathname.endsWith("mp3")){
            mime_type = "audio/mp3";
            file_name = title + ".mp3";
        }
        else if(file_url_obj.pathname.endsWith("opus")){
            mime_type = "audio/opus";
            file_name = title + ".opus";
        }
        else if(file_url_obj.pathname.endsWith("jpeg") ||
                file_url_obj.pathname.endsWith("jpg")
        ){
            mime_type = "image/jpeg";
            file_name = title + ".jpeg";
        }
        else if(file_url_obj.pathname.endsWith("png")){
            mime_type = "image/png";
            file_name = title + ".png";
        }
        file_name = file_name.replace(/\s/g,"-");

        let file_data = _file_bytes_cache[file_url] = new File(
            [file_bytes], 
            file_name, 
            {
                type: mime_type,
            }
        );

        if(link){
            /* appending link because url param doesn't work fully yet */
            share_text += "\n" + link;
        }
        let to_share = {
            files: file_data ? [file_data]: [],
            title: title,
            text: share_text,
            url: link  // does not work fully yet
        };
        if (navigator.canShare(to_share)){
            navigator.share(to_share).then(function(){
                /*share successful*/
                cb && cb(true);
            }).catch(function(err){
                if(err && err.toString().indexOf("NotAllowedError") != -1){
                    navigator.share({
                        title: title,
                        text: share_text,
                        url: link
                    });
                }
                cb && cb(false);
            });
            setStatusText("sharing..");
        }
        else{
            /*
                unable to share files so share whatever we can 
                and call it successs
            */
            navigator.share({
                title: title,
                text: share_text,
                url: link
            }).catch(function(err){

            });
            setStatusText("sharing link..");
            cb && cb(true);
        }
        // TODO: Enable download
    }

    const doShare = () => {
        if(file_url && navigator && navigator.canShare){ // share file

            let _cached_file = _file_bytes_cache[file_url];
            if(_cached_file){
                after_file_downloaded(_cached_file);
                return;
            }

            setStatusText("sharing..");

            let to_download = new URL(file_url, window.location.href).href;
            if(to_download.indexOf("?") < 0){
                to_download+="?";
            }
            to_download += ("&_rand_=" + parseInt(new Date().getTime())); // add random to prevent caching
            var req = new XMLHttpRequest();
            req.open("GET", to_download, true);
            req.responseType = "arraybuffer";

            req.onprogress = function (evt) {
                if(evt.total){
                    setStatusText(parseInt(evt.loaded/evt.total * 100) + "% download");
                }
            };
            req.onerror = function(evt){
                setStatusText("Error Downloading");
                cb && cb(false);
            };
            req.onload = function(evt){
                after_file_downloaded(req.response);
            };
            req.send(null);
        } else if(link && navigator && navigator.share){ // share url
            navigator.share({
                title: title,
                text: share_text,
                url: link
            }).catch(function(err){
                
            });
            setStatusText("Sharing...");
            cb && cb(true);
        } else{
            cb && cb(false);
            window.open(link || file_url, "_blank", "noreferrer");
        }
    }
    
    return (
        <div className={className} onClick={
            (evt) => doShare()
        }>
            {status_text || children}
        </div>
    );
}

/* 
    Enables tag selections
    tag -> {title, value, selection}
    selection -> {title, value} # override to alt selection when selected
    selection -> null -> text search
*/
function TagsInput({
    selected:_selected, placeholder, suggestions,
    onSelectedChange, onTextSearch, className, no_suggestions_display
}){
    const [selected, setSelected] = useState([]);
    const [filtered_suggestions, setFilteredSuggestions] = useState(null);
    const [highlighted_suggestion, setHighlightedSuggestion] = useState(-1);
    const [search_text, setSearchText] = useState("");
    const input_el_ref = useRef();
    const ctx = useRef({"deleted": []}).current;

    useEffect(() => {setSelected(_selected || [])}, [_selected]);
    
    const addSelection = (v) => {
        if(v.selection !== undefined){
            if(v.selection){
                addSelection(v.selection);
            } else {
                doTextSearch(search_text);
            }
            return;
        }
        let new_selected = [...selected, v];
        onSelectedChange(new_selected);
        setSelected(new_selected);
        setSearchText("");
    }

    const removeSelection = (v) => {
        let new_selected = selected.filter((_v) => _v !== v)
        onSelectedChange(new_selected);
        setSelected(new_selected);
        v && ctx.deleted.push(v);
    }

    const doTextSearch = (text) => {
        onTextSearch && onTextSearch(text);
        ctx.last_text_search = text;
        setFilteredSuggestions(null);
    }

    /* handle enter / arrow keys to cycle through */
    useEffect(
        () => {
            const handleKeyDown = (evt) => {
                if(!filtered_suggestions) return;
                if(evt.key === "Enter"){
                    if(filtered_suggestions && filtered_suggestions.length){
                        addSelection(
                            filtered_suggestions[highlighted_suggestion]
                            || filtered_suggestions[0] 
                        );
                    }
                } 
                //escape
                else if(evt.key === "Escape"){
                    setFilteredSuggestions(null);
                }
                // arrow down
                else if(evt.key === "ArrowDown"){
                    setHighlightedSuggestion((highlighted_suggestion + 1) % filtered_suggestions.length);
                } 
                else if(evt.key === "ArrowUp"){
                    setHighlightedSuggestion(highlighted_suggestion > 0 ? highlighted_suggestion - 1 : -1);
                }
            }
            setHighlightedSuggestion(-1);
            document.addEventListener("keydown", handleKeyDown);
            return () => document.removeEventListener("keydown", handleKeyDown);
        },
        [filtered_suggestions]
    );

    useEffect(
        () => {
            if(!search_text) {
                setFilteredSuggestions(null);
                return;
            }
            if(isFunction(suggestions)){
                suggestions(search_text).then((s) => {setFilteredSuggestions(s);});
            } else {
                setFilteredSuggestions(
                    suggestions?.filter(
                        (s) => {
                            let _s = s.title || s;
                            return _s.toLowerCase().includes(search_text.toLowerCase())
                        }
                    )
                );
            }
        }, [search_text]
    );

    const onTextInputKeyUp = (evt) => {
        if(evt.key === 'Backspace'){
            let last_selected = last(selected);
            if(!search_text){
                if(last_selected){
                    if(!ctx.backspaace_pressed_twice) ctx.backspaace_pressed_twice = 1;
                    else {removeSelection(last_selected); ctx.backspaace_pressed_twice = 0;}
                } else if(ctx.last_text_search){
                    doTextSearch("");
                    ctx.last_text_search = null;
                }
            } else{
                ctx.backspaace_pressed_twice = 0;
            }
        }
        /* check ctrl-z */
        else if(evt.ctrlKey && evt.key === 'z'){
            let last_deleted = last(ctx.deleted);
            if(last_deleted){
                addSelection(last_deleted);
                ctx.deleted.pop();
            }
        }
    }

    return (
        <div className={`w3-border w3-input w3-flex-row-wrap tw-space-1 w3-white ${className || ''}`}
            onClick={(evt) => {
                (
                    evt.target === input_el_ref.current?.parentElement
                    || evt.target == evt.currentTarget
                ) && input_el_ref.current.focus()
            }}
        >
            {
                selected.map((v) => {
                    const title = v.title || v;
                    return (
                        <div className='w3-tag w3-no-wrap w3-margin-right-8' style={{"backgroundColor": getRandomColor(title)}}
                            key={title}
                        >
                            <FontAwesomeIcon icon={faX} 
                                onClick={(evt) => removeSelection(v)} />&nbsp;{title}
                        </div>
                    )
                })
            }
            <div className='w3-flex-grow-s1 w3-relative'>
                <input className="w3-border-none w3-decoration-none w3-inline w3-expand"
                    ref={input_el_ref}
                    value={search_text}
                    placeholder={placeholder || 'Search'}
                    onChange={(evt) => setSearchText(evt.target.value)}
                    onKeyUp={onTextInputKeyUp}
                />
                {   
                    filtered_suggestions
                    ?   <div style={{"position": "absolute", "top": "100%", "minWidth": "200px"}}
                            className='w3-white w3-2px-shadow'
                        >
                            <div className='w3-list w3-list-bordered'>
                                {
                                    filtered_suggestions.map((v, i) =>
                                        <div className="w3-padding-8-16" 
                                            key={i}
                                            style={{"backgroundColor": highlighted_suggestion === i ? "lightgrey" : "white"}}
                                            onClick={(evt) => {addSelection(v)}}
                                        >{v.title || v.view || v}</div>
                                    )
                                }
                            </div>
                        </div>
                    :   search_text && no_suggestions_display
                        ?   <div style={{"position": "absolute", "top": "100%", "minWidth": "200px"}}
                                className='w3-white w3-2px-shadow'
                            >{no_suggestions_display}</div>
                        :   null
                }
            </div>
        </div>
    );
}

function SelectTags({tags, selected_tags:_selected_tags, onSelectedTagsUpdated, className, placeholder_text}){
    const [filtered_tags, setFilteredTags] = useState(tags);
    const [selected_tags, setSelectedTags] = useState(_selected_tags || []);
    const [search_text, setSearchText] = useState("");

    useEffect(() => {_selected_tags?.length && setSelectedTags(_selected_tags)}, [_selected_tags]);
    useEffect(
        () => {
            if(isFunction(tags)){
                tags(search_text).then((s) => {setFilteredTags(s);});
            } else {
                setFilteredTags(
                    tags?.filter(
                        (s) => {
                            let _s = s.title || s;
                            return _s.toLowerCase().includes(search_text.toLowerCase())
                        }
                    )
                );
            }

        }, [selected_tags, search_text]
    )
    const handleTagSelection = (tag) => {
        const new_selected_tags = selected_tags.includes(tag) 
            ? selected_tags.filter((t) => t !== tag)
            : [...selected_tags, tag];
        setSearchText("");
        setSelectedTags(new_selected_tags);
        onSelectedTagsUpdated && onSelectedTagsUpdated(new_selected_tags);
    };
    return (
        <div className={className}>
            {
                selected_tags.length > 0 
                ?   <div className='w3-flex-row-wrap w3-list-horizontal w3-margin-bottom-8'>
                        {   
                            selected_tags.map(
                                (tag, i) => 
                                    <div className='w3-tag w3-round w3-border w3-border-black' key={tag}>
                                        {tag} <span className='w3-large' onClick={() => handleTagSelection(tag)}>&times;</span>
                                    </div>
                            ) 
                        }
                    </div>
                :   null
            }
            <div className='w3-row w3-relative'>
                {
                    !tags.length || tags.length > 10
                    ?   <input type="text" className='w3-input w3-margin-bottom'
                            value={search_text}
                            onChange={(evt) => setSearchText(evt.target.value)}
                            placeholder={placeholder_text || 'Search tags'}
                        />
                    :   null
                }
                <div className='w3-list w3-scroll' style={{"maxHeight": "200px"}}>
                    {
                        filtered_tags.map(
                            (tag, i) =>
                                <div className='w3-col s6' key={i}>
                                    <label className='w3-flex w3-flex-vcenter w3-list-horizontal-16'>
                                        <div>
                                            <input type="checkbox"
                                                checked={selected_tags.includes(tag)}
                                                onChange={() => handleTagSelection(tag)}
                                                className='w3-check' />
                                        </div>
                                        <div>{tag}</div>
                                    </label>
                                </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

function onServiceWorkerUpdateFound(registration) {
    if(!registration) return;
    registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
}

/* categories is list of string a > b > c */
function CategorySelectorStrip({categories, onCategoriesSelected}){
    const [category_tree, setCategoryTree] = useState({});
    const [selected_category_keys, setSelectedCategoryKeys] = useState([]);
    useEffect(
      () => {
        let category_tree = {};
        categories.forEach(
          (c) => setKeyPath(category_tree, c.split(/>|&gt;/).map((_c) => _c.trim().toLowerCase()))
        );
        setCategoryTree(category_tree);
      }, [categories]
    );
    const levels = [Object.keys(category_tree)]; // first level should be visible
    let _category_tree = category_tree;
    for(let i=0;i<selected_category_keys.length;i++){
      _category_tree = _category_tree[selected_category_keys[i]];
      _category_tree && levels.push(Object.keys(_category_tree));
    }
  
    const onCategorySelected = (category, level_index) => {
      let _selected_category_keys = selected_category_keys.slice(0, level_index)
      if(selected_category_keys[level_index] !== category){ // toggle
        _selected_category_keys.push(category);
      }
      setSelectedCategoryKeys(_selected_category_keys);
      onCategoriesSelected?.(_selected_category_keys);
    }
  
  
    return <div className='w3-padding-8 w3-flex-row-wrap w3-list-horizontal-16'>
      {
        levels.map(
            (l, i) => {
              return (
                <div className='w3-capitalize w3-flex w3-no-wrap w3-flex-vcenter w3-center w3-list-horizontal-16 w3-scroll-x w3-padding-bottom-8 w3-hide-scrollbar w3-animate-left'
                    key={i}
                    style={{
                        "borderLeft": i > 0 ? "1px solid black" : null,
                        "paddingLeft": i > 0 ? "8px" : null,
                    }}
                >
                  {
                    l.map(
                      (c) => (
                        <div className='w3-padding-8 w3-border w3-round-xlarge'
                          style={{
                            "border": selected_category_keys[i] === c ? "3px solid black" : null,
                          }}
                          key={c} onClick={() => onCategorySelected(c, i)}
                        >{c}</div>
                      )
                    )
                  }
                </div>
              );
            }
          )
        }
    </div>
}

function ListAndDictView({data}){
    if(Array.isArray(data)){
        return <table>
            <tbody>
                {data.map((d, i) => {
                    return  <tr key={i}>
                        <td><ListAndDictView data={d} /></td>
                    </tr>
                })}
            </tbody>
        </table>
    }
    else if(typeof data === "object"){
        return <table>
            <tbody>
                {Object.entries(data).map(([k, v]) => {
                    return <tr key={k}>
                        <td>{k}</td>
                        <td><ListAndDictView data={v} /></td>
                    </tr>
                })}
            </tbody>
        </table>
    } else {
        return data;    
    }
}

const schemaTypeObject = (schema) => schema.type === "array" ? [] : schema.type === "object" ? {} : "";

function SchemaObjectCreator({schema, schema_path, data, data_path, onUpdate}){
    schema_path = schema_path || [];
    data_path = data_path || [];
    if(data_path.length == 0 && !data) data = schemaTypeObject(schema);
    var _schema = getKeyPath(schema, schema_path);
    if(isString(_schema)) _schema = {"type": schema};
    const _data = getKeyPath(data, data_path);
    const rerender = useRerender();

    if(_schema.type === 'object'){
        return <>        
            {_schema.description && <div>{_schema.description}</div>}
            <table className='w3-table-all'>
                <tbody>
                    {
                        Object.entries(_schema.properties).map(([key, value]) => (
                            <tr key={key}>
                                <td>
                                    {value?.title || key}
                                    {value?.required && <span className='w3-text-red'>*</span>}
                                </td>
                                <td>
                                    <SchemaObjectCreator
                                        schema={schema}
                                        schema_path={[...schema_path, "properties", key]}
                                        data={data}
                                        data_path={[...data_path, key]}
                                        onUpdate={onUpdate}
                                    />
                                </td>
                            </tr>
                        ))
                    }
               </tbody>
            </table>
        </>
    }
    else if(_schema.type === 'array'){
        return <>
            {_schema.description && <div className='w3-small w3-text-grey w3-padding-topbottom-8'>{_schema.description}</div>}
            <div className='w3-list-horizontal-16 w3-flex'>
                {
                    _schema.items.oneOf?.length
                    ?   <select onChange={(evt) => {
                            _schema.use_one_of = parseInt(evt.target.value)
                            _schema._next_path = ["oneOf", _schema.use_one_of];
                        }}>
                            <option value="">Select Type</option>
                            {
                                _schema.items.oneOf.map((option, index) => (
                                    <option key={index} value={index}>{option.description || option.type}</option>
                                ))
                            }
                        </select>
                    :   null
                }
                <button onClick={
                    () => {
                        let _next_schema = _schema.items; // default
                        if(_schema.use_one_of !== undefined){ 
                            _next_schema = _schema.items.oneOf[_schema.use_one_of];
                        }
                        else if(_schema.use_any_of !== undefined){
                            _next_schema = _schema.items.anyOf[schema.use_any_of];
                        }
                        (_data || setKeyPath(data, data_path, [])).push(schemaTypeObject(_next_schema));
                        onUpdate?.(data);
                        rerender();
                    }
                }>Add Item</button>
            </div>
            <table>
                <tbody>
                    {
                        _data?.map?.((d, i) => (
                            <tr key={i + d.toString()}>
                                <td>
                                    <SchemaObjectCreator 
                                        schema={schema}
                                        schema_path={[...schema_path, "items", ...(_schema._next_path || [])]}
                                        data={data}
                                        data_path={[...data_path, i]}
                                        onUpdate={onUpdate}
                                    />
                                </td>
                                <td>
                                    <FontAwesomeIcon icon={faTrash} 
                                        onClick={() => {
                                            _data.splice(i);
                                            rerender();
                                            onUpdate?.(data);
                                        }
                                    }/>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </>
    }
    else if(!_schema.type || _schema.type === "string"){
        return <>
            {_schema.description && <div className='w3-small w3-text-grey w3-padding-topbottom-8'>{_schema.description}</div>}
            <input type={"text"} defaultValue={_data} 
                className='w3-input'
                onChange={(evt) => {
                    setKeyPath(data, data_path, evt.target.value);
                    onUpdate?.(data);
                }} 
                placeholder={"enter a value"}
            />
        </>
    } else {
        return <>
            {_schema.description && <div className='w3-small w3-text-grey  w3-padding-topbottom-8'>{_schema.description}</div>}
            <input type={"text"} defaultValue={_data}
                className='w3-input'
                onChange={(evt) => {
                    setKeyPath(data, data_path, parseInt(evt.target.value));
                    onUpdate?.(data);
                }} 
                placeholder={"enter a number"}
            />
        </>
    }
}

function TextAreaWithCheckBox({title, defaultValue, onChange, className, placeholder, input_type}){
    const [show, setShow] = useState(isString(defaultValue));
    const [value, setValue] = useState(defaultValue || "");
    const ctx = useRef({"prev_value": show ? value : null}).current;
    useEffect(
        () => {
            const new_value = show ? value : null;
            ctx.prev_value !== new_value && onChange(new_value); // only call when value changes
            ctx.prev_value = new_value;
        }, [show, value]
    );

    return (
        <div className="w3-list">
            <label className="w3-flex w3-flex-vcenter">
                <input className="w3-check" type="checkbox"
                    onChange={(evt) => setShow(evt.target.checked)} 
                    checked={show}
                />
                <div className="w3-margin-left">{title}</div>
            </label>
            {
                show 
                ?   !input_type
                    ?   <input type="text" defaultValue={defaultValue}
                            className={className || "w3-input"}
                            placeholder={placeholder}
                            onChange={(evt) => setValue(evt.target.value)}
                        />
                    : <textarea defaultValue={defaultValue}
                            className={className || "w3-input"}
                            placeholder={placeholder}
                            onChange={(evt) => setValue(evt.target.value)}
                        />
                :   null
            }
        </div>
    );
}

function InfoText({text}){
    return <span 
        onClick={(evt) => {
            evt.stopPropagation();
            Popup.showContextMenu(
                evt.currentTarget,
                <div className='w3-padding-8' style={{"maxWidth": "400px"}}>
                    {text}
                </div>
            )
        }}
    >
        <FontAwesomeIcon icon={faInfoCircle}/>
    </span>
}

function EditableTextArea({text, placeholder, onUpdate}){
    const [is_editing, setIsEditing] = useState(false);
    const ctx = useRef({"edited_text": text}).current;
    const doUpdateText = async (evt) => {
        setIsEditing(false);
        if(ctx.edited_text === text) return;
        try{await onUpdate(ctx.edited_text)}
        catch(e){setIsEditing(true);}
    }

    return (
        <div className='tw-flex tw-flex-row tw-gap-2'>
            {
                is_editing
                ?   <>
                        <textarea defaultValue={text} 
                            onChange={(evt) => ctx.edited_text = evt.target.value}
                            className='w3-input' placeholder={placeholder}  
                        />
                        <FontAwesomeIcon className="tw-text-lg tw-mt-1" icon={faCircleCheck} onClick={doUpdateText} />
                    </>
                :   <div className="tw-gap-2 tw-flex tw-flex-row" onClick={() => setIsEditing(true)}>
                        {text || <span className='tw-text-gray-300'>{placeholder}</span>}
                    </div>
            }
        </div>
    )
}

export {
    ShortImageAndDescription, SimpleTableInput, TableInput, NamedCheckboxAttribute, UserShortBadge, PagesSelection, ToolTip, Share, SelectTags, ListAndDictView, SchemaObjectCreator, schemaTypeObject,
    onServiceWorkerUpdateFound, CSS_COLOR, CategorySelectorStrip, CrammedUsersList, HorizontalUsersList, VerticalUsersList, TagsInput,
    TextAreaWithCheckBox, UserBadge, InfoText, EditableTextArea, RoundUserImage
};
