import axios from "axios"
import { useLocalStorageState } from "base/utils/events"
import React, {useEffect} from "react"
import { useRef } from "react"
import { useState } from "react"

function StripeConnect({}){
	const [org_id] = useLocalStorageState("org_id", null) || ["test"];
	const [payment_gateways, setPaymentGateways] = useState({});
	const [wallets, setWallets] = useState({});
	const ctx = useRef({}).current;
	useEffect(
		() => {
			if(ctx.is_loading) return;
			ctx.is_loading = true;
			axios.get(`/api/admin/org/${org_id}/payments`).then(
				(resp) => {
					setPaymentGateways(resp.data.payment_gateways)
					setWallets(resp.data.wallets)
				}
			).catch((ex) => {})
			.finally(() => {ctx.is_loading = false;});
		}, []
	);

	const doConnectOrUpdateStripe = () => {
		if(ctx.is_connecting) return;
		ctx.is_connecting = true;
		axios.post(`/api/admin/org/${org_id}/payments?action=connect_or_update_stripe`).then(
			(resp) => {
				if(resp.data.redirect){ // open in new tag
					window.open(resp.data.redirect, "_blank")
				}
			}
		).catch((ex) => {})
		.finally(() => {
			ctx.is_connecting = false;
		});
	}


	const doGotoStripeDashboard = () => {
		if(ctx.is_connecting) return;
		ctx.is_connecting = true;
		axios.post("/api/org/{+org_id}/payments?action=goto_stripe_dashboard").then(
			(resp) => {
				if(resp.data.redirect){ // open in new tag
					window.open(resp.data.redirect, "_blank")
				}
			}
		).catch((ex) => {})
		.finally(() => {
			ctx.is_connecting = false;
		});
	}
	
	return (
		<div className="tw-p-4 tw-m-2 tw-space-y-4 tw-border tw-rounded-md" 
			style={{"maxWidth": "500px"}}
		>
			<div className="tw-text-lg tw-font-semibold">Stripe Connect</div>
			<div>
				{
					!payment_gateways.stripe?.config?.details_submitted
					? <div className="w3-button w3-indigo tw-inline" onClick={doConnectOrUpdateStripe}> Connect With Stripe</div>
					: null
				}
			</div>
			{
				payment_gateways.stripe?.config?.charges_enabled
				? 	<div>
						Your Stripe account is <span className="tw-text-green-800">connected</span>
					</div>
				: 	null
			}
			{
				payment_gateways.stripe?.data?.requirements?.currently_due?.length
				? 	<div>
						<div className="tw-text-red-500">Please complete the following fields in your Stripe account</div>
						{
							payment_gateways.stripe.data.requirements.currently_due.currently_due.map(
								(field) => <div>{field}</div>
							)
						}
					</div>
				: 	payment_gateways.stripe?.config?.connected_account_id
					?	<div className="tw-flex tw-flex-row tw-gap-2">
							<button className="tw-bg-blue-100 tw-rounded-md tw-px-4 tw-py-2" onClick={doConnectOrUpdateStripe}>Update Account</button>
							<button className="tw-bg-green-100 tw-rounded-md tw-px-4 tw-py-2" onClick={doGotoStripeDashboard}>Stripe Dashboard</button>
						</div>
					:	null
			}
		</div>
	)
}

export {StripeConnect};