import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
  useNavigate,
  Link,
} from "react-router-dom";

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { IS_DEV } from 'base/constants';

import 'base/css/w3.css';
import { Popup } from 'base/ui/popups';
import { OrgSettingsView } from './org';
import { EditProfilePage, Login, UserProfilePage } from 'base/login';
import { useCurrentUser } from 'base/app';
import "./css/variables.css";
import "./css/app.css";
import "./css/calendar.css";
import "./setupTailwind"
import { WithSideMenu } from './base/ui/container_with_side_menu';
import {faBlackboard, faGear, faTicket, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TicketsPage } from './tickets';
import { OrgUsersPage } from './user';
import BG_IMG from './images/bg.png';


const root = ReactDOM.createRoot(document.getElementById('root'));

function App() {
  const navigate = useNavigate();
  const user = useCurrentUser();
  if(user === undefined) return null;  // loading
  if(!user){
    return (
      <div className='w3-relative w3-padding' style={{"height": "500px"}}>
        <Login className="w3-display-middle w3-expand-x" />
      </div>
    )
  };

  // if(!org_id){
  //   return <EmptyView title="No organization selected" height={"500px"}/>
  // }

  const isSelectedPathCss = (path) => {return window.location.pathname.startsWith(path) ? "tw-font-bold" : ""}

  return (
    <WithSideMenu header_class_name='w3-border-bottom'>
      {/* header */}
      <div className='w3-flex-row w3-flex-grow-s1 w3-flex-vcenter'>
        <div className='w3-bold' onClick={() => navigate("/tickets")}>
          Sukhiba
        </div>
      </div>
      {/* content */}
      <div className='tw-relative tw-flex tw-flex-col tw-space-y-2 tw-pt-8 tw-h-full tw-text-white'
        style={{"backgroundColor": "#052F2F"}}
      >  
        <div style={{"backgroundImage": `url(${BG_IMG})`, "opacity": 0.1, "pointerEvents": "none" }} className='tw-inset-0 tw-absolute'></div>   
        <Link to={'/tickets'} className={`tw-px-4 tw-py-2 tw-mx-2 ${isSelectedPathCss("/tickets")}`}>
          <FontAwesomeIcon icon={faTicket} className="w3-margin-right" />Tickets
        </Link>
        <Link to={'/users'} className={`tw-px-4 tw-py-2 tw-mx-2 ${isSelectedPathCss("/users")}`}>
          <FontAwesomeIcon icon={faUserAlt} className="w3-margin-right" />Users
        </Link>
        <Link to={'/landing-page'} className={`tw-px-4 tw-py-2 tw-mx-2 ${isSelectedPathCss("/landing-page")}`}>
          <FontAwesomeIcon icon={faBlackboard} className="w3-margin-right" />Landing Page
        </Link>
        <Link to={'/settings/flows'} className={`tw-px-4 tw-py-2 tw-mx-2 ${isSelectedPathCss("/settings")}`}>
          <FontAwesomeIcon icon={faGear} className="w3-margin-right" />Settings
        </Link>
      </div>
      {/* content */}
      <div className='w3-relative' style={{"minHeight": "500px"}}>
        <Routes>
          <Route path="/tickets" element={<TicketsPage />} exact />
          <Route path="/users" element={<OrgUsersPage />} exact />
          <Route path="/settings/*" element={<OrgSettingsView />} />
          <Route path="/profile" element={<UserProfilePage />} exact />
          <Route path="/edit/profile" element={<EditProfilePage />} exact />
        </Routes>
      </div>
    </WithSideMenu>
  );
}

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/*" element={<App />} />
      </Routes>
      {<Popup />}
    </Router>
  </React.StrictMode>
);


IS_DEV 
  ? serviceWorkerRegistration.unregister()
  : serviceWorkerRegistration.register({
      onUpdate: (registration)=> {
        registration?.waiting?.postMessage({type: 'SKIP_WAITING'});
      }
  });

IS_DEV && reportWebVitals(console.log);
