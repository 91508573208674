import { useBroadcastedState, setBroadcastHookState } from "../utils/events";
import React, { useEffect, useRef } from 'react';



function enableSwipeableSideMenu(el, overlay_bg, ctx) {
    var el_width = el.getBoundingClientRect().width;
    var offset_visible = 20;
    var el_left = 0;
    var touch_start_x = 0;
    var touch_move_x = 0;
    var shift_for_start = 50;

    var is_open = false;

    var open_menu = ctx.open_menu = function () {
        el.style.transitionDuration = "0.2s";
        el.style.transform = 'translateX(0px)'; // open
        overlay_bg.style.display = "block";
        overlay_bg.style.opacity = "1";
        is_open = true;
    }

    var close_menu = ctx.close_menu = function () {
        el.style.transform = 'translateX(' + (-el_width + offset_visible) + 'px)'; // open
        overlay_bg.style.display = "none";
        overlay_bg.style.opacity = "0";
        is_open = false;
    }

    const touch_start = function (evt) {
        el_left = el.getBoundingClientRect().left;
        touch_start_x = evt.changedTouches[0].clientX;
        el.style.transitionDuration = '0s';
        overlay_bg.style.display = "block";
    }

    const touch_move = function (evt) {
        touch_move_x = evt.changedTouches[0].clientX;
        var el_left_new = (touch_move_x - touch_start_x) + el_left;
        if (el_left_new <= 0) {
            if (touch_start_x > el_width) {
                el_left_new = el_left_new + (touch_start_x - el_width);
            }
            if (touch_move_x <= el_width) {
                el.style.transform = "translateX(" + el_left_new + "px)";
            }
            let opacity = (el_left_new + el_width - offset_visible) / (el_width - offset_visible);
            if (opacity >= 0 && opacity <= 1) {
                overlay_bg.style.opacity = opacity;
            }
        }
    }

    const touchend = function (evt) {

        var touch_end_x = evt.changedTouches[0].clientX;
        //set translation time to 0.2
        el.style.transitionDuration = '0.2s';

        if (!is_open && touch_end_x > touch_start_x) {
            if (Math.abs(touch_start_x - touch_end_x) > shift_for_start) {
                open_menu();
            } else {
                close_menu();
            }
        } //touch_end_x!==touch_start_x, equal for click evt
        else if (is_open && touch_end_x < touch_start_x && touch_end_x <= el_width) {
            if ((touch_start_x > el_width && touch_end_x < el_width - shift_for_start)
                || (touch_start_x < el_width
                    && Math.abs(touch_start_x - touch_end_x) > shift_for_start
                )
            ) {
                close_menu();
            } else {
                open_menu();
            }
        }
    }

    const menu_item_click = function(evt) {
        close_menu();
    }

    const evt_listeners = [
        [overlay_bg, "touchmove", close_menu],
        [overlay_bg, "click", close_menu],
        [el, "touchstart", touch_start],
        [el, "touchmove", touch_move],
        [el, "touchend", touchend],
        [el, "click", menu_item_click],
    ];

    for (let [el, evt, handler] of evt_listeners) {
        el.addEventListener(evt, handler);
    }

    return () => {
        for (let [el, evt, handler] of evt_listeners) {
            el.removeEventListener(evt, handler);
        }
    }    
}

function WithSideMenu({ children: [header, menu, body], closed_by_default = false, header_class_name}) {
    const [{WIDTH, HEIGHT}] = useBroadcastedState("viewport");
    const is_large_screen = WIDTH >= 993;
    const side_bar_collapsed = !is_large_screen || closed_by_default;
    const side_bar_ref = useRef(null);
    const overlay_bg_ref = useRef(null);
    const ctx = useRef({}).current;

    useEffect(
        () => {
            if(side_bar_ref.current && overlay_bg_ref.current){
                return enableSwipeableSideMenu(
                    side_bar_ref.current,
                    overlay_bg_ref.current,
                    ctx
                );
            }
        }, [is_large_screen, ctx]
    );

    return (
        <>           
            {
                side_bar_collapsed
                ?   <header className={`${header_class_name || ""}`}>
                        <div className="w3-flex-row">
                            <div className="w3-button w3-margin-right" onClick={() => ctx.open_menu?.()}>&#9776;</div>
                            {header}
                        </div>
                    </header>
                :   null
            }
            <div ref={overlay_bg_ref} className="w3-overlay" style={{ cursor: 'pointer', opacity: 0 }}></div>
            <div className='w3-flex'>
                {
                    !side_bar_collapsed
                    ?   <nav className='w3-white w3-flex-vstretch'
                            style={{
                                "minWidth": "250px",
                                "width": "250px",
                                "position": "sticky",
                                "top": "0px",
                                "height": HEIGHT+"px"
                            }}
                        >
                            {menu}
                        </nav>
                    :   <nav
                            ref={side_bar_ref}
                            style={{
                                width: '250px', height: '100%',
                                overflow: 'hidden', position: 'fixed',
                                zIndex: 30, top: '0px', transform: 'translateX(-280px)'
                            }}
                        >
                            <div className="w3-white w3-scroll w3-hide-scrollbar"
                                style={{ position: 'absolute', left: '0px', top: '0px', bottom: '0px', right: '20px' }}
                            >
                                {
                                    is_large_screen
                                    ?   <div className="fa fa-times w3-transparent w3-right w3-pointer" style={{ padding: '5px 10px' }}
                                            onClick={() => ctx?.close_menu?.()}
                                        ></div>
                                    :   null
                                }
                                {menu}
                            </div>
                        </nav>
                }
                <div className='w3-flex-grow-s1 w3-bounded'>
                    {body}
                </div>
            </div>
        </>
    )
}

export { WithSideMenu };