import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getUKey } from './utils';
import { IS_MOBILE } from 'base/constants';
import { useRerender, useRerenderWithValue } from 'base/utils/common';

/* 
  onReorder(dest_position, src_position, dest_id, src_id) => Promise<boolean>
*/
var ctx = {};
const ReorderableList = ({ children:_children, className, onReorder, list_id}) => {
  list_id = list_id || "";
  /* enable dragstart after 3 seconds of touch  */
  const [children, setChildren] = useState([]);
  const [_render, rerender] = useRerenderWithValue();
  useEffect(
    () => {
      /* clean */
      if(ctx.prev_dragover) ctx.prev_dragover.style.paddingTop = "0";      
      if(ctx.draggable_el){
        ctx.draggable_el.removeAttribute("draggable");
        ctx.draggable_el.style.display = "block";
      }
      ctx.prev_dragover = null;
      ctx.draggable_el = null;

      setChildren([
        ...React.Children.toArray(_children),
        <div style={{height: "50px"}}></div>
      ])
    }, 
    [_children, _render]
  );

  const setupDraggable = (e) => {
    ctx.draggable_el = e.target.closest("[__draggable__]");
    ctx.draggable_el?.setAttribute("draggable", true);
    ctx.draggable_clientHeight = ctx.draggable_el.clientHeight;
  }

  /* set up the dragging element */
  const handleTouchStart = (e) => {
    e.persist();
    ctx.touch_start = e.target;
    /* check if clicked on draggable hodler */
    if(e.target.getAttribute("is_draggable")){
      setupDraggable(e);
      return;
    }
    /* detect long press */
    if(ctx.timeout) ctx.timeout= clearTimeout(ctx.timeout);
    ctx.timeout = setTimeout(() => {if(e.target == ctx.touch_start) setupDraggable(e);}, 500);
  };

  const handleTouchEnd = (e) => {
    /* cleanup */
    ctx.touch_start = null;
    if(ctx.timeout) ctx.timeout= clearTimeout(ctx.timeout);
    ctx.draggable_el && ctx.draggable_el.removeAttribute("draggable");
    ctx.draggable_el = null;
  }

  const handleDragStart = (e, position) => {
    e.dataTransfer.setData("text/plain", [position, list_id].join(","));
    setTimeout(() => {ctx.draggable_el.style.display = "none";}, 0); // hide original element
  };
  
  const handleDragOver = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    /* show space on top the the current dragable */
    let _parent = e.target.closest("[__draggable__]");
    if(ctx.prev_dragover) ctx.prev_dragover.style.paddingTop = "0";
    ctx.prev_dragover = _parent;
    if(!_parent) return;
    ctx.prev_dragover.style.paddingTop =  ctx.draggable_clientHeight + "px";
  });
  
  const handleDrop = useCallback(async (e, to_pos) => {
    /* perform reorder */
    e.stopPropagation();
    let _parent = e.target.closest("[__draggable__]");
    let promise = null;
    if(_parent){
      const [from_position, from_list_id] = e.dataTransfer.getData("text/plain").split(",");
      children.splice(to_pos, 0, children.splice(from_position, 1)[0]);
      onReorder && onReorder(to_pos, from_position, list_id, from_list_id)
    }
    rerender();
    promise && await promise;
  });

  useEffect(
    () => {
      document.body.addEventListener("dragover", handleDragOver);
      document.body.addEventListener("drop", handleDrop);
      return () => {
        document.body.removeEventListener("dragover", handleDragOver);
        document.body.removeEventListener("drop", handleDrop);
      };
    },
    [handleDragOver, handleDrop]
  );


  const disableContextMenu = (el) => {
    IS_MOBILE &&
      el?.addEventListener("contextmenu", (e) => e.preventDefault());
  }

  return (
    <div className={className} ref={(el) => disableContextMenu(el)}>
      {children.map((el, i) => (
        <div className='w3-noselect'
          key={el.key || i}
          __draggable__={i}
          onDragStart={(e) => handleDragStart(e, i)}
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, i)}
          onTouchStart={(e) => handleTouchStart(e)}
          onTouchEnd={handleTouchEnd}
          onMouseDown={(e) => handleTouchStart(e)}
          onMouseUp={handleTouchEnd}
        >
          {el}
        </div>
      ))}
    </div>
  );
};

export default ReorderableList;
