// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --primary-color: #2B2134;
    --primary-bg-color: #F6F9F9;
    --primary-light-color: #EFF8F4;
    --secondary-color: #635EFD; 
    --secondary-light-color: #dd9d75;
    --secondary-bg-color: #e9e9fd;
    --secondary-bg-light-color: #FFFCF9;
    --primary-btn-color: linear-gradient(90deg, #635EFD 0%, #A863F6 100%);
}`, "",{"version":3,"sources":["webpack://./src/css/variables.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,2BAA2B;IAC3B,8BAA8B;IAC9B,0BAA0B;IAC1B,gCAAgC;IAChC,6BAA6B;IAC7B,mCAAmC;IACnC,qEAAqE;AACzE","sourcesContent":[":root {\n    --primary-color: #2B2134;\n    --primary-bg-color: #F6F9F9;\n    --primary-light-color: #EFF8F4;\n    --secondary-color: #635EFD; \n    --secondary-light-color: #dd9d75;\n    --secondary-bg-color: #e9e9fd;\n    --secondary-bg-light-color: #FFFCF9;\n    --primary-btn-color: linear-gradient(90deg, #635EFD 0%, #A863F6 100%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
