import { faDollarSign, faGlobe, faMagicWandSparkles, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import { IS_DEV } from "base/constants";
import { DateView } from "base/ui/date";
import { EditableTable } from "base/ui/editable_table";
import { GenericException } from "base/ui/errors";
import { EmptyView, LoadingView } from "base/ui/status";
import { useRerender } from "base/utils/common";
import { useLocalStorageState } from "base/utils/events";
import { useEffect, useRef, useState } from "react";
import GSHEET_ICON from "./images/gsheets.svg";
import { WithSideMenu } from "./base/ui/container_with_side_menu";
import { Link, Route, Routes } from "react-router-dom";
import { StripeConnect } from "./payments";


function OrgSettingsView() {
  const [org_id] = useLocalStorageState("org_id", IS_DEV ? "test": null);
  const [org_admin_config, setOrgAdminConfig] = useState(undefined);
  const ctx = useRef({}).current;
  /* initial loading */
  useEffect(
    () => {
      axios.post(`/api/admin/org/${org_id}/settings`).then(
        (resp) => {
          if(resp.data.errors){GenericException.showPopup(resp.data.errors); return;}
          setOrgAdminConfig(resp.data.admin_config);
        }
      );
    }, []
  );

  const isSelectedLinkCss = (path) => {return window.location.pathname === path ? "tw-border-b tw-border-blue-600" : ""}
  if(org_admin_config === undefined) return <LoadingView title="Loading" height="300px" />

  return (
    <div className='tw-pt-4'>     
      <div className="tw-max-w-full tw-flex tw-flex-row tw-gap-4 tw-mb-4 tw-overflow-x-auto tw-border-b tw-border-gray-100">
        <Link to={'/settings/flows'} className={`tw-px-4 tw-py-2  ${isSelectedLinkCss("/settings/flows")}`}>
          <FontAwesomeIcon icon={faMagicWandSparkles} className="w3-margin-right" />Flows
        </Link>
        <Link to={'/settings/payments'} className={`tw-px-4 tw-py-2 ${isSelectedLinkCss("/settings/payments")}`}>
          <FontAwesomeIcon icon={faDollarSign} className="w3-margin-right" />Payments
        </Link>
        <Link to={'/settings/domain'} className={`tw-px-4 tw-py-2 ${isSelectedLinkCss("/settings/domain")}`}>
          <FontAwesomeIcon icon={faGlobe} className="w3-margin-right" />Domain
        </Link>
      </div>
      <div className='w3-relative' style={{"minHeight": "500px"}}>
        <Routes>
          <Route path="flows" element={<FlowsSettingsPage org_admin_config={org_admin_config} />} exact />
          <Route path="payments" element={<PaymentsPage org_admin_config={org_admin_config} />} exact />
          <Route path="domain" element={<EmptyView title="Domain" />} exact />
        </Routes>
      </div>
    </div>
  )

}

function FlowsSettingsPage({org_admin_config: _org_admin_config}) {
  
  const [org_admin_config, reloadAdminConfig] = useState(_org_admin_config);
  const [flow_gsheets_info, setFlowGSheetsInfo] = useState({});
  const [settings_gsheets_info, setSettingsSheets] = useState({});
  const ctx = useRef({}).current;
  const rerender = useRerender();


  const loadGSheetsInfo = (gsheets_info) => {
    const _flow_gsheets_info = {};
    const _settings_sheets_info = {};
    Object.entries(gsheets_info).forEach(([gsheet_id, gsheet_info]) => {            
      if(gsheet_info.sheet_title.startsWith("flow")) _flow_gsheets_info[gsheet_id] = gsheet_info;
      if(gsheet_info.sheet_title.startsWith("settings")) _settings_sheets_info[gsheet_id] = gsheet_info;
    });
    setFlowGSheetsInfo(_flow_gsheets_info);
    setSettingsSheets(_settings_sheets_info);
  }

  useEffect(
    /* setup sheets table */
    () => {
      loadGSheetsInfo(org_admin_config.gsheets_info);
    }, [org_admin_config]
  );

  /* sync sheets */
  const doSync = (gsheet_id) => {
    if(ctx.is_syncing) return;
    ctx.is_syncing = gsheet_id;
    rerender();
    axios.post(
      `/api/admin/org/gsheetsync`, 
      {"gspreadsheet_id": org_admin_config.gspreadsheet_id, "gsheet_id": gsheet_id}
    ).then((resp) => {
      if(resp.data.errors) GenericException.showPopup(resp.data.errors);
      const gsheets_info = resp.data.gsheets_info;
      gsheets_info && loadGSheetsInfo(gsheets_info);
    }).finally(() => {
      ctx.is_syncing = null;
      rerender();
    });
  }

  return (
    <div className="w3-padding">
      <EditableTable
        cols={[
          {
            "key": "ai_model",
            "title": "AI Model",
            "description": "Monthly billing changes based on the AI models",
            "editor": (row, updates) => null,
            "size": {"l": 100}
          },
          {
            "key": "ai_prompt",
            "title": "AI Persona",
            "size": {"l": 100}
          },              
          {
            "key": "gspreadsheet_id",
            "title": "Google Spreadsheet ID",
            "render": (row) => {
              return (
                <div className="w3-flex-row">
                  <img src={GSHEET_ICON} alt={"gsheet_icon"} width={"20px"} className="w3-margin-right"/>
                  <span className="w3-small">{row.gspreadsheet_id || "No Sheet"}</span>
                </div>
              );
            },
            "size": {"l": 100}
          },
        ]}
        rows={[org_admin_config]} // only one row
        callbacks={{
          "onUpdate": async (row, updates) => {
            const resp = await axios.post(`/api/admin/org/${org_admin_config._id}/settings?action=update_gspreadsheet`, updates);
            reloadAdminConfig(resp.data.admin_config);
            return [row, resp.data.errors];
          }
        }}
        L={1}
      />
      <div className="tw-mt-4">
        <div className="w3-col l6 s12">
          <div className="w3-bold">Flows</div>
          {
            <table className="w3-table-all">
              <tbody>
                {
                  Object.entries(flow_gsheets_info)
                  .sort((a, b) => (b[1].last_synced_at || 0) - (a[1].last_synced_at || 0))                
                  .map(([sheet_id, gsheet_info]) => {
                    return <tr key={gsheet_info.sheet_id}>
                      <td>{gsheet_info.sheet_title}</td>
                      <td><DateView millis={gsheet_info.last_synced_at} /></td>
                      <td>
                        <FontAwesomeIcon icon={faSpinner} spin={ctx.is_syncing === sheet_id} 
                          onClick={() => doSync(sheet_id)} 
                        />
                      </td>
                    </tr>
                  })
                }
              </tbody>
            </table>
          }
        </div>
        {
          Object.entries(settings_gsheets_info).length > 0
          ? <div className="w3-col l6 s12">
              <div className="w3-bold">Settings</div>
              <table className="w3-table-all">
                <tbody>
                  {
                    Object.entries(settings_gsheets_info)
                    .sort((a, b) => (b[1].last_synced_at || 0) - (a[1].last_synced_at || 0))                
                    .map(([sheet_id, gsheet_info]) => {
                      return <tr key={gsheet_info.sheet_id}>
                        <td>{gsheet_info.sheet_title}</td>
                        <td><DateView millis={gsheet_info.last_synced_at} /></td>
                        <td>
                          <FontAwesomeIcon icon={faSpinner} spin={ctx.is_syncing === sheet_id} 
                            onClick={() => doSync(sheet_id)} 
                          />
                        </td>
                      </tr>
                    })
                  }
                </tbody>
              </table>
            </div>
          : null
        }
      </div> 
    </div>
  )
}


function PaymentsPage(){
  return <StripeConnect />
}


export { OrgSettingsView };
